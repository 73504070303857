$card-grid-color: #fff !default; 

.card-grid {
	.columns {
		margin-bottom: use_gutter();
		// block level link; wraps .block - legacy
		> a {
			display: block;
			position: relative;
			height: 100%;
		}
	}
	.block {
		position: relative;		
		height: 100%;
		overflow: hidden;
		padding: 20px;
		@include breakpoint(small) {
			min-height: 100px;
		}
		// 'hidden' link inside .block
		> a {
			@include hidden-link(4);
			&:hover ~ .bgcolor {
				opacity: 0.85;
			}
		}
	}
	.image,
	.bgcolor {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
	.image {
		z-index: 1;
		background-size: cover;
		background-position: center;
	}
	.bgcolor {
		z-index: 2;
		transition: 0.35s;
	}
	.contents {
		position: relative;
		z-index: 3;

		li {
			margin-bottom: 3px;
		}
	}
	.icon {
		position: relative;
		z-index: 3;
		font-size: 2.5em;
	}
	.header:last-child,
	.description:last-child {
		margin-bottom: 0;
	}
	&:not(&-center):not(&-animate-text) {
		.header {
			display: table;
		}
	}
	.description {
		color: $body-font-color;
		clear: both;
	}
	
	// Justify vertical spacing of content elements
	&.align-justify {
		.block {
			display: flex;
		}
		.contents {
			display: flex;
			flex-flow: column nowrap;
			flex: 1 1 auto;
			justify-content: space-between;
			align-items: baseline;
		}
	}

	&-bgcolor {
		// default colours
		.icon,
		.header,
		.description,
		.description * {
			color: #fff;
		}
		.button {
			background: transparent;
			border: solid 1px white;
			&:hover {
				background: rgba(white, 0.15);
			}
		}

		// auto adjust colours based on contrast (colour class must also be placed on ".block")
		@each $color, $value in $foundation-palette { 
			// override default colours for yellow
	  	@if $color == yellow { 
	      $value: $yellowdarker; 
	    }

		  .#{$color}.block {
		  	[class*="icon_"],
		  	.header,
				.description,
				.description *,
				a {
		  		color: color-pick-contrast($value, ($button-color, $darkblue)); //$button-color-alt
		  	}
		  	.button {
		  		border-color: color-pick-contrast($value, ($button-color, $darkblue));
		  		color: color-pick-contrast($value, ($button-color, $darkblue));
		  	}
		  }
		}

		.columns > a:hover {
			.bgcolor {
				opacity: 0.9;
			}
		}
	}
	&:not(&-bgcolor):not(&-gradient) {
		.bgcolor {
			background: get-color(lightgrey);
		}
	}

	&-gradient {
		.bgcolor {
			background: linear-gradient($darkerblue, $lightblue);
			opacity: 1 !important;
		}
		.header, .description {
			color: $white;
		}
		.contents a:not(.button) {
			@include link-style($white);
			color: $white !important;
		}
		.contents > .button {
			background: transparent;
			border: solid 1px white;
			&:hover {
				background: rgba(white, 0.15);
			}
		}
		.list-brand-arrows li a {
			color: $white !important;
			border-bottom-color: transparent !important;
			background-image: none !important;
			&:hover {
				border-bottom-color: $white !important;
			}
		}
	}
	
	&-center {
		.icon {
			margin-bottom: 0.375em;
			display: block;
			font-size: 3em;
			text-align: center;
			opacity: 0.7;
		}
		&:not(.card-grid-animate-text) {
			text-align: center;
			.contents {
				align-items: center;
			}
		}		
	}
	&-center-vertical {
		.block {
			display: flex;
			justify-content: center;
			flex-flow: column nowrap;
		}
	}
	&:not(&-center) {
		.icon {
			margin-right: 20px;
			margin-bottom: 10px;
			float: left;
		}
	}
	&-bottom {
		.block {
			display: flex;
			align-items: flex-end;
			padding: 30% 20px 20px;
		}
	}

	&-bgicon {
		.bgicon {
			position: absolute;
			bottom: -.5rem;
			right: -.5rem;		
			margin: 0; 
			font-size: 7rem;
			opacity: .1;
			transition: 0.35s;
			z-index: 3;
		}
	}
	
	&-image {
		&:not(.card-grid-bgcolor):not(.card-grid-image-half):not(.card-grid-image-full) { 
			* {
				color: #fff;
			}
			.header, .contents, .icon {
				text-shadow: 1px 1px 0px rgba(0,0,0,0.2);
				opacity: 1;
			}
			.description {
				font-size: 1.2rem;
				text-shadow: 1px 1px 3px rgba(0,0,0,0.8);
			}			
			.block {
		    position: relative;
		    overflow: hidden;
		    
		    &:hover {
		    	.image {
		    		transform: scale(1.1);
		    	}
		    }
			}
			&:not([class*='card-grid-center']) {
				.block {
					padding: 30% 20px 20px;
					display: flex;
				}
			}
			&.card-grid-animate-text {
				.block {
					flex-flow: column;
					justify-content: center;
					align-items: flex-start
				}
			}
			.image {
		    transition: 0.35s;
		    &:before {
			    content: "";
			    background: rgba(0,0,0,0.3);
			    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
			    width: 100%;
			    height: 100%;
			    position: absolute;
			    top: 0;
			    left: 0;
				}
			} 
			.button {
				background: transparent;
				border: solid 1px white;
				&:hover {
					background: rgba(white, 0.15);
				}
			}
		}
		
		&:not(.card-grid-image-half):not(.card-grid-image-full) {
			.bgcolor {
				opacity: 0.75;
			}
			.columns > a:hover,
			.block:hover {
				.bgcolor {
					opacity: .9;
				}
			}
		}

		&.video-thumbnail .image::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			// z-index: 2;
			width: 100%;
			height: 100%;
			background: url('https://shared.ontariotechu.ca/shared/ontario-tech/stock/video-thumbnail-play-button.png') center center no-repeat;
			background-size: 30% auto;
			opacity: .8;
		}
	}
	
	&-image-half {	
		.contents {
			margin-top: 100px;
		}
		&.card-grid-center:not(.card-grid-animate-text) .contents {
			margin-top: 0;
		}

		.image {
			height: 100px;
			transition: 0.35s;
		}

		.icon {
			margin-top: 100px;
		}
		&.card-grid-animate-text .icon {
			margin-top: 50px;
		}

		.bgcolor {
			top: 100px;
		}
		.columns > a:hover,
		.block:hover {
			.image {
				transform: scale(1.1);
			}
		}
	}

	&-image-full {
		.image {
			position: relative; 
			width: calc(100% + 40px);
			height: auto;
			margin: -20px -20px 20px;
			overflow: hidden;
			z-index: 3; 

			img {
				width: 100%;
				transition: 0.35s;
			}
		}
		.columns > a:hover,
		.block:hover {
			.image img {
				transform: scale(1.08);
			}
		}
	}

	&-animate-text {
		.block {
			display: flex;
			align-items: center;
			&:hover {
				.contents,
				.icon {
					transform: translateX(0);
				}
				.icon {
					opacity: 0.7;
				}
				.bgicon {
					opacity: 0.4;
				}
			}
		}
		.contents {
			padding-left: 5em;
			transform: translateX(-5em);
			transition: 0.35s;
		}
		.header {
			@include breakpoint (medium) {
				font-size: 2.25em;
			}			
		}
		.icon {
			position: absolute;
			left: 20px;
			opacity: 0;
			transform: translateX(-100px);
			transition: 0.35s;
			font-size: 3em;
		}
		&.card-grid-center .icon {
			font-size: 4em;
		}
	}
}
