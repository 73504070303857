/* - - - - - - - - - - - - - - - - - - - - - - - - -

  TABLE OF CONTENTS
  
  - Icon list (map)
  - .icon placeholder
  - Set individual icons
  - get-icon() function

- - - - - - - - - - - - - - - - - - - - - - - - - */


// Icon list (map)
// - - - - - - - - - - - - - - - - - - - - - - - - -

$icon-list: (
	icon_accessibility: '\e8b6',
	icon_airplane: '\e82f',
  icon_apple: '\e83c', 
  icon_arrow: '\e800', 
  icon_arrow_down: '\e831', 
  icon_arrow_left: '\e800', 
  icon_arrow_right: '\e833', 
  icon_arrow_up: '\e834', 
  icon_atom: '\e83d', 
  icon_audience: '\e83e', 
  icon_award: '\e822', 
  icon_backpack: '\e966', 
	icon_balancescale: '\e95e', 
	icon_baseball: '\EA32',
	icon_basket: '\e82c', 
	icon_basketball: '\EA08',
  icon_battery: '\e840', 
  icon_bell: '\e958', 
  icon_blackboard: '\e8ab', 
  icon_blocks: '\e842', 
  icon_book: '\e96e', 
  icon_books: '\e80a', 
  icon_book_ot: '\e803', 
  icon_brain: '\E844', 
  icon_bricks: '\e845', 
  icon_briefcase: '\e965', 
  icon_building: '\e96a', 
  icon_bunsenburner: '\e846', 
  icon_bus: '\e847', 
  icon_calculator: '\e848', 
  icon_calendar: '\e81b', 
  icon_calendardate: '\e968', 
	icon_camera: '\e8b5', 
	icon_canvas: '\E82D',
	icon_cart: '\EA30',
	icon_close: '\e82e', 
	icon_closebox: '\E9F1',
  icon_car: '\e849', 
  icon_cc_mastercard: '\f1f1', 
  icon_cc_visa: '\f1f0', 
  icon_chat: '\e832', 
  icon_check: '\E9E9', 
  icon_checkbox: '\e84d', 
	icon_chevron_up:  '\e994',
	icon_chevron_down: '\e995',
	icon_chevron_left: '\e996',
	icon_chevron_right: '\e997',
	icon_chevron_top_left: '\e8d1',
	icon_chevron_top_right: '\e8d2',
	icon_chevron_bottom_left: '\e8d3',
	icon_chevron_bottom_right: '\e8d4',
  icon_clipboard: '\E814', 
	icon_clipboard-grad: '\e88d',
	icon_clock: '\E84E', 
	icon_cloud: '\e9de',
	icon_cloud_lightning: '\EA53',
	icon_cloud_sun: '\e9db',
	icon_cloud_rain: '\e9dc',
  icon_code: '\e84f',  
  icon_compass: '\e805', 
	icon_computer: '\e9c2',
  icon_computerchip: '\e852', 
  icon_construction: '\e853', 
  icon_cross: '\e825', 
  icon_curling: '\e855', 
  icon_cursor: '\e826', 
  icon_dancer: '\e856', 
  icon_dashboard: '\e857', 
	icon_desk: '\e970',
  icon_diploma: '\e858', 
  icon_direction: '\e8bc', 
  icon_directory: '\e80e', 
  icon_dna: '\e85a', 
	icon_door: '\e971',
	icon_download: '\e8b8',
	icon_drink: '\EA87',
	icon_drop: '\e851',
  icon_dropper: '\e85b', 
	icon_easel: '\e8c1', 
	icon_easel2: '\ea23',
  icon_elevator: '\e95a', 
  icon_emergency: '\e804', 
	icon_emoji-smile: '\0e82',
  icon_energy: '\e85c', 
  icon_engine: '\e85d', 
  icon_envelope: '\e836', 
  icon_file: '\E9F3',   
	icon_file_doc: '\e9f4',
	icon_file_eps: '\e9f5',
	icon_file_gif: '\e9f6',
	icon_file_image: '\e9f7',
	icon_file_jpg: '\e9f8',
	icon_file_js: '\e9f9',
	icon_file_css: '\e9fa',
	icon_file_exe: '\e9fb',
	icon_file_information: '\e9fd',
	icon_file_locked: '\e9fe',
	icon_file_pdf: '\e9ff',
	icon_file_php: '\ea00',
	icon_file_png: '\ea01',
	icon_file_psd: '\ea02',
	icon_file_rss: '\ea03',
	icon_file_svg: '\ea04',
	icon_file_text: '\e821', 
	icon_file_zip: '\ea05',
	icon_flask: '\e841', 
	icon_flower: '\e88e',
  icon_folder: '\e93e', 
  icon_font: '\e8ba', 
  icon_fontsize: '\f088', 
  icon_friends: '\e810', 
  icon_gamecontroller: '\e85f', 
  icon_gasmask: '\e860', 
  icon_gavel: '\e861', 
  icon_gear: '\e863', 
  icon_gears: '\e86b', 
  icon_glasses: '\e864', 
  icon_globe: '\e865', 
  icon_globenetwork: '\e865', 
  icon_golf: '\e866', 
  icon_gonfalon_fbit: '\e8b0', 
  icon_gonfalon_feas: '\e948', 
  icon_gonfalon_fed: '\e947', 
  icon_gonfalon_fesns: '\e8af', 
  icon_gonfalon_fhs: '\e8b1', 
  icon_gonfalon_fsci: '\e8ad', 
  icon_gonfalon_fssh: '\e8ae', 
  icon_gradcap: '\e867', 
  icon_graduate: '\e81d', 
  icon_grid: '\f0ce', 
	icon_grid2: '\e8c0',
	icon_grid3: '\EA28', 
	icon_chart: '\ea1c', 
	icon_chart2: '\EA21',
	icon_chart3: '\E875',
  icon_handcuffs: '\e868', 
  icon_handshake: '\e82a', 
  icon_hazard: '\e86d', 
  icon_heart: '\e829', 
  icon_hockey: '\e86f', 
  icon_home: '\e862', 
	icon_icecream: '\e80b',
	icon_idcard: '\0E87',
	icon_iecs-bear:'\e96f',
  icon_image: '\e8b3',
	icon_inbox_down: '\EA2D',
	icon_inbox_up: '\EA2E',
  icon_info: '\e8bd', 
  icon_key: '\E9E4', 
  icon_lacrosse: '\e95b', 
  icon_laptop: '\E81C', 
  icon_leaf: '\e872', 
  icon_letters: '\e83b', 
	icon_lightbulb: '\e873', 
	icon_lightning: '\EA50',
  icon_link: '\e809', 
	icon_list: '\E813',
  icon_lock: '\E807', 
	icon_lock_laptop: '\e843',
	icon_lock_social_media: '\e84c',
  icon_lockeddrive: '\e876', 
  icon_locker: '\e877', 
  icon_magnifyingglass: '\e82b',
  icon_mail: '\e836', 
  icon_map: '\E806', 
  icon_mapmarker: '\E802', 
  icon_mathsymbols: '\e879', 
  icon_mechanicalarm: '\e87b', 
  icon_medkit: '\e8b2', 
  icon_megaphone: '\e87c', 
  icon_menu: '\e808', 
  icon_message: '\e962', 
  icon_message2: '\EA3F', 
  icon_message3: '\EA40', 
  icon_message4: '\EA41', 
  icon_microphone: '\e963', 
  icon_microscope: '\e87d', 
  icon_minion: '\e811', 
	icon_molecules: '\e880', 
	icon_money: '\E9C3',
	icon_mouse: '\e87e', 
	icon_mouse_window: '\E9E1',
	icon_multifactor_authentication: '\e83f',
  icon_music: '\e967', 
  icon_needle: '\e87f', 
	icon_network: '\e86a', 
	icon_network2: '\EA2A',
  icon_oars: '\e881', 
  icon_otshield: '\e8ca', 
  icon_palette: '\e8c2',  
  icon_paper: '\e821', 
  icon_paperclip: '\e883', 
	icon_pause: '\e80d', 
  icon_paw: '\e96d', 
  icon_pen: '\e828', //e884
  icon_pencil: '\e828', 
  icon_pencilholder: '\e885', 
	icon_people_profile: '\e81e', 
	icon_people_group: '\e893',
  icon_people_single: '\e815',  
	icon_phishing: '\e84a',
  icon_phone: '\e839',  
	icon_piechart: '\EA25',
  icon_piggybank: '\e8a2', 
  icon_pill: '\e8a3', 
  icon_pi: '\e8c3',
  icon_pi-outline: '\e8a6', 
  icon_pin-outline: '\e8bf', 
  icon_play: '\e80c', 
  icon_plug: '\e8a4', 
  icon_points: '\e8a5', 
  icon_printer: '\e824', 
  icon_professor2: '\e89d', 
  icon_professor: '\e827', 
  icon_proton: '\e960',  
  icon_question: '\e801', 
  icon_reading: '\e887', 
	icon_recycle: '\f1b8',
  icon_relativity: '\e888', 
  icon_ruler: '\e820',  
  icon_ruler2: '\e889',
  icon_sailormoon: '\e812', 
  icon_scales: '\e895', 
	icon_search: '\e80f', 
  icon_settings: '\e863', 
  icon_sharelink: '\e896', 
  icon_skeletalhand: '\e897', 
  icon_sling: '\e898', 
  icon_smartphone: '\E899', 
	icon_snowflake: '\e9dd', 
  icon_soccer: '\e88a', 
  icon_social_facebook: '\e817', 
  icon_social_instagram: '\e819', 
	icon_social_linkedin: '\e816', 
	icon_social_snapchat: '\e835', 
	icon_social_tiktok: '\e830',
  icon_social_twitter: '\e818', 
  icon_social_youtube: '\e81a', 
	icon_solarpanels: '\e88b', 
	icon_spin: '\e837',
  icon_stein: '\e88c', 
	icon_stethoscope:  '\f0f1', 
  icon_sun: '\e972', 
  icon_sunflowers: '\e88e', 
  icon_tablet: '\e81f', 
  icon_tennis: '\e88f', 
  icon_testtube: '\e890', 
  icon_testtubes: '\e891',
	icon_thermometer:'\e854',
  icon_ticket: '\e838', 
  icon_tie: '\e89a', 
  icon_tools: '\e8a1', 
  icon_train: '\e89c',  
	icon_trash:'\e96b',
	icon_trophy: '\e823', 
	icon_turtle: '\e8d0',
  icon_universal-access: '\e8b7', 
  icon_upload: '\e8be', 
	icon_usbkey: '\e89e', 
	icon_videocamera: '\e8bb',
	icon_volume: '\e957', 
	icon_volume_mute: '\EA14',
  icon_warning: '\e804', 
  icon_watch: '\e89f', 
	icon_water: '\e850',
  icon_wifi: '\e8a0', 
  icon_windmill: '\e869', 
	icon_working_from_home: '\e84b',


	// legacy names
  icon_alarmbell: '\E958', 
	icon_askaquestion: '\e801', 
  icon_atom2: '\e83d', 
  icon_bargraph: '\EA1C', 
  icon_batterylevel: '\e840', 
  icon_belloutline: '\E958',
  icon_bookuoit: '\e803',  
  icon_brainoutline: '\e844', 
  icon_camera-outline: '\e8b5', 
  icon_cancel: '\e82e', 
  icon_canceloutline: '\e82e', 
  icon_carside: '\e849', 
  icon_cc-mastercard: '\f1f1', 
  icon_cc-visa: '\f1f0', 
  icon_chatbubbles: '\EA41', 
  icon_chattyping: '\EA41',  
	icon_checkoutline: '\E9E9',
  icon_church: '\e96a',  
	icon_clock2: '\E84E', 
  icon_codingbrackets: '\e84f',
	icon_coin: '\E9C3',  
  icon_coins: '\E9C3',
  icon_coinsdollar: '\E9C3', 
  icon_coinlaptop: '\e9c2',
  icon_constructionworkers: '\e853', 
	icon_compass2: '\e805',
  icon_directionalarrows: '\e8bc', 
  icon_download-alt: '\e8b8', 
  icon_downloadviewbooks: '\e803',  
	icon_download_laptop: '\e83a',
  icon_drive: '\e849', 
  icon_exploreuoit: '\e805',  
  icon_file-pdf: '\E9FF', 
	icon_findaperson: '\e80e', 
  icon_folderwithprofilegear: '\e93e', 
	icon_flowchart: '\e86a', 
  icon_gradcap2: '\e867', 
  icon_greenlightbulb: '\e873', 
  icon_hunter: '\e96d',
  icon_idandlaptop: '\e81e', 
	icon_idlock: '\e81e', 
  icon_infoclipboard: '\E814', 
  icon_laptopcheckboxes: '\E81C', 
  icon_laptopgear: '\E81C', 
  icon_laptopsound: '\E81C', 
  icon_laptoptablet: '\E81C', 
  icon_laptoptools: '\E81C', 
  icon_launchtour: '\e806',  
  icon_leaveamessage: '\e807',
  icon_linechart: '\E875', 
	icon_linegraph: '\E875',   
	icon_link-ext: '\E9E1', 
  icon_list-bullet: '\E813', 
  icon_list-numbered: '\E813', 
	icon_mailoutline: '\e836', 
  icon_mail-outline: '\e836', 
  icon_map-outline: '\e806', 
  icon_map-signs: '\e806', 
  icon_measuringtape: '\e820', 
  icon_moneybag: '\E9C3', 
  icon_mouse2: '\E87E', 
  icon_health: '\e8b2',
  icon_page: '\e821',  
  icon_paper2: '\e821',
  icon_pencil2: '\e828', 
  icon_penwriting: '\e828', 
	icon_person: '\e81e', 
	icon_personchat: '\e802', 
  icon_picture: '\e8b3',
  icon_profilesettings: '\E863', 
  icon_qa: '\EA41',
  icon_quicklinks: '\e809', 
  icon_readers: '\e887', 
  icon_runner: '\e815', 
  icon_runners2: '\e893', 
  icon_runners: '\e893', 
  icon_sound: '\e957', 
  icon_speechbubble: '\e962', 
  icon_stopwatch: '\e89f', 
  icon_table: '\f0ce', 
	icon_teacher: '\e89d', 
  icon_tennisball: '\e88f', 
  icon_thumbnails: '\e8c0', 
  icon_transit: '\e847',
  icon_uoitshield: '\e8ca', 
  icon_videocam: '\e8bb', 
  icon_viewprograms: '\e80a',
  icon_walk: '\e815',  
  icon_walking: '\E815',
	
);


// .icon placholder
// - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin icon {
  display: inline-block;
  font-family: $uoit_icon_font;
  // font-size: 14px;
  line-height: 1; 
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox
}
.icon {
  @include icon; 
}



// Set individual icons
// - - - - - - - - - - - - - - - - - - - - - - - - -

[class*="icon_"] {
  @include icon;
  @each $name, $icon in $icon-list {
    &.#{$name}:before {  
      font-family: $uoit_icon_font;// !important;
      content: $icon;
    } 
  }
}
.icon_minion {
  color: orange;
}


// get-icon() function
// - - - - - - - - - - - - - - - - - - - - - - - - -

@function get-icon($key) {
  @if map-has-key($icon-list, $key) {
    @return map-get($icon-list, $key);
  }
  @else {
    @error 'given $key is not available in $icon-list';
  }
}
