/* - - - - - - - - - - - - - - - - - - - - - - - - -
  
  Related files: 
  - components/containers/feature-module

  TABLE OF CONTENTS
  
  1.  General layout
  2.  Swiper
      a.  Swiper layout
      b.  Article link
      c.  Article text
      d.  Swiper controls
  3.  1 column layout
`
- - - - - - - - - - - - - - - - - - - - - - - - - */

.news { 

  // 1. General layout
  // - - - - - - - - - - - - - - - - - - - - - - - - -
  
  @include transition(width $transition-duration linear);
  @include row_gutter();

  @include breakpoint(xxsmall up) {
    @include grid-column(12);
  }
  @include breakpoint(medium up) {
    @include flex-grid-column(8);
  } 

  .home-module {
    .content {    
      // height: 100%;
      @include breakpoint(xxsmall only) {
        @include clear; 
      }
      @include breakpoint(medium) { 
        max-height: $max_newsevents_height;

        display: flex;
        // flex-flow: column nowrap;
        // align-items: flex-end;
      }
    }
  }



  // 2. Swiper
  // - - - - - - - - - - - - - - - - - - - - - - - - -
  
  // 2.a. Swiper layout
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  .swiper-container {  
    @include breakpoint(xxsmall only) {
      width: 100%;
    }
    @include breakpoint(xsmall up) {     
      width: 90%;
      // height: 100%;
      margin: 0 5%;
    }
    // flex: 1 1 auto;
  }

  .swiper-slide { 

    // 2.b. Article link
    // - - - - - - - - - - - - - - - - - - - - - - - - - 

    > a {
      position: relative;
      display: block;
      height: 100%;
      background: #fff;
      overflow: hidden; 
      color: inherit;  
      
      img { 
        // @include breakpoint(xxsmall only) {
          width: 100%;
        // }
        // @include breakpoint(xsmall only) {
          width: 100%;
        // }
      } 
      
      // hover effect
      .news_img {
        position: relative;       
      } 
      .news_img::before {
        content: '';
        display: block;
        background: scale-color($primary-color, $alpha: -25%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0; 
        @include transition(all $transition-duration ease-in-out);
      }
      .news_img::after {
        content: 'Read story';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        font-family: $font-body;
        font-weight: 900;
        font-size: 1rem; 
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        @include transform(translateY(-50%));
        @include transition(all $transition-duration ease-in-out);
        opacity: 0;
      }
      &:hover, &:active, &:focus {  
        .news_img::before,
        .news_img::after {
          opacity: 1;
        }   
      }
    } // article link
    

    // 2.c. Article text
    // - - - - - - - - - - - - - - - - - - - - - - - - -
    
    .news_text {
      padding: use_gutter();
      background: #fff;      
    
      p.news_date {
        margin-bottom: 0;
        @include typography-primary-link($accent-color);
      }
      p.news_title {
        margin: 0;
        font-size: rem-calc(15);
        line-height: normal;
      }
    } // .news_text 
     
  } // .swiper-slide 

  
  // 2.d. Swiper controls
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  .swiper-prev, .swiper-next {
    position: absolute;
    top: 0;
    left: 0;
    width: 5%; 
    height: 100%;
    display: block;
    padding: 0;
    margin: 0;
    background: scale-color($module_bg_light, $lightness: -10%);
    font-size: rem-calc(32);
    @include transition(all $transition-duration ease-in-out); 

    @include breakpoint(xxsmall only) {
      position: static;
      float: left;
      width: 49%;
      height: rem-calc(40);
      margin: use_gutter(0.5) 0 0;
      font-size: rem-calc(26);
      line-height: rem-calc(40);
    }
    @include breakpoint(xsmall only) {
      font-size: rem-calc(24);
    }
    
    &:after {
      @include icon;
      content: get-icon(icon_arrow); 
      color: scale-color($module_bg_light, $lightness: -30%);
    }
          
    &:hover, &:active, &:focus {  
      background: scale-color($primary-color, $alpha: -25%);
    } 
    
    span {  
      display: inline-block;
      width: 0;
      text-indent: -9999px;
    }
  } // .swiper-prev, .swiper-next

  .swiper-next {
    left: auto;
    right: 0;

    @include breakpoint(xxsmall only) {
      float: right;
    }

    &::after {
      @include transform(rotate(180deg));
    }
  }

} // .news


// 3. 1 column layout
// - - - - - - - - - - - - - - - - - - - - - - - - -

.news.news_1col {
  @include breakpoint(medium up) {
    @include flex-grid-column(4);

    .home-module .content {
      height: auto;
      background: #fff;
    }

    .swiper-container { 
      width: 80%;
      margin: 0 10%;
    }
    
    .swiper-prev, .swiper-next {
      width: 10%;
    }
  } 
  
} // .news.news_1col