@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,700');

// Franklin Gothic Book
@font-face {
  font-family: 'Franklin Gothic';
  src: url($font-file-path + 'franklingothicbook/FranklinGothic-Book-webfont.eot');
  src: url($font-file-path + 'franklingothicbook/FranklinGothic-Book-webfont.eot?#iefix') format('embedded-opentype'),
       url($font-file-path + 'franklingothicbook/FranklinGothic-Book-webfont.woff') format('woff'),
       url($font-file-path + 'franklingothicbook/FranklinGothic-Book-webfont.ttf') format('truetype'),
       url($font-file-path + 'franklingothicbook/FranklinGothic-Book-webfont.svg#franklin_gothic_fsbook') format('svg');  
  font-weight: 400;
  font-style: normal;     
}

// Franklin Gothic Medium
@font-face {
    font-family: 'Franklin Gothic';
    src: url($font-file-path + 'franklingothicmedium/FranklinGothic-Med-webfont.eot');
    src: url($font-file-path + 'franklingothicmedium/FranklinGothic-Med-webfont.eot?#iefix') format('embedded-opentype'),
         url($font-file-path + 'franklingothicmedium/FranklinGothic-Med-webfont.woff') format('woff'),
         url($font-file-path + 'franklingothicmedium/FranklinGothic-Med-webfont.ttf') format('truetype'),
         url($font-file-path + 'franklingothicmedium/FranklinGothic-Med-webfont.svg#franklin_gothic_fsmedium') format('svg');
    font-weight: bold;
    font-style: normal;
}

// Franklin Gothic Demi
@font-face {
  font-family: 'Franklin Gothic';
  src: url($font-file-path + 'franklingothicdemi/FranklinGothic-Demi-webfont.eot');
  src: url($font-file-path + 'franklingothicdemi/FranklinGothic-Demi-webfont.eot?#iefix') format('embedded-opentype'),
       url($font-file-path + 'franklingothicdemi/FranklinGothic-Demi-webfont.woff') format('woff'),
       url($font-file-path + 'franklingothicdemi/FranklinGothic-Demi-webfont.ttf') format('truetype'),
       url($font-file-path + 'franklingothicdemi/FranklinGothic-Demi-webfont.svg#franklin_gothic_fsdemi') format('svg');
  font-weight: 900;
  font-style: normal;
}

// Franklin Gothic Condensed - legacy; some svgs still use Condensed
@font-face {
  font-family: 'Franklin Gothic Condensed';
  src: url($font-file-path + 'franklingothiccondensed/FranklinGothic-Cd-webfont.eot');
  src: url($font-file-path + 'franklingothiccondensed/FranklinGothic-Cd-webfont.eot?#iefix') format('embedded-opentype'),
       url($font-file-path + 'franklingothiccondensed/FranklinGothic-Cd-webfont.woff') format('woff'),
       url($font-file-path + 'franklingothiccondensed/FranklinGothic-Cd-webfont.ttf') format('truetype'),
       url($font-file-path + 'franklingothiccondensed/FranklinGothic-Cd-webfont.svg#franklin_gothic_fscondensed') format('svg');
  font-weight: 400;
  font-style: normal;
}

// Icon font - generated on uoit_icons
@font-face {
  font-family: 'UOIT Icons';
  src: url($font-file-path + 'uoiticons/uoit_icons.eot'); //55309185
  src: url($font-file-path + 'uoiticons/uoit_icons.eot#iefix') format('embedded-opentype'),
       url($font-file-path + 'uoiticons/uoit_icons.woff') format('woff'),
       url($font-file-path + 'uoiticons/uoit_icons.ttf') format('truetype'),
	     url($font-file-path + 'uoiticons/uoit_icons.svg#uoit_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
