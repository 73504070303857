//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Close Button
//  15. Drilldown
//  16. Dropdown
//  17. Dropdown Menu
//  18. Flex Video
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Meter
//  24. Off-canvas
//  25. Orbit
//  26. Pagination
//  27. Progress Bar
//  28. Reveal
//  29. Slider
//  30. Switch
//  31. Table
//  32. Tabs
//  33. Thumbnail
//  34. Title Bar
//  35. Tooltip
//  36. Top Bar
//  37. Card
//  38. Responsive Embed

@import 'foundation-sites/scss/util/util';

// 1. Global
//    Note: base variable values are defined in scss/settings/_base-variables.scss
// ---------

$primary-color: $darkblue;
$secondary-color: $lightblue;
$tertiary-color: $greymedium !default;
$accent-color: $orange !default;
$accent-color-dark: $darkorange !default;
$accent-alt-color: $grey !default;

// alternate variables
$primary: $primary-color;
$secondary: $secondary-color;
$tertiary: $tertiary-color;
$accent: $accent-color;

$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #151515;
$white: #ffffff;

$font-body: $franklinGothic !default; 
$font-heading: $ubuntu !default;  
$font-alt-heading: $ubuntu !default; 
$font-serif: $ubuntu !default;

$global-font-size: 112.5%;
$global-width: rem-calc(1400);
$global-lineheight: 1.5;

$foundation-palette: (
	primary: $primary-color,
	secondary: $secondary-color,
	tertiary: $mediumgrey,
	accent: $accent-color,
	darkaccent: $accent-color-dark,

	coolgrey: $coolgrey,
	lightcoolgrey: $lightcoolgrey,
	mediumcoolgrey: $mediumcoolgrey,
	coolgrey10: $coolgrey10,
	coolgrey20: $coolgrey20,
	coolgrey30: $coolgrey30,
	coolgrey40: $coolgrey40,
	coolgrey50: $coolgrey50,
	coolgrey60: $coolgrey60,
	coolgrey70: $coolgrey70,
	coolgrey80: $coolgrey80,
	coolgrey90: $coolgrey90,

	warmgrey: $warmgrey,
	lightwarmgrey: $lightwarmgrey,
	mediumwarmgrey: $mediumwarmgrey,
	warmgrey10: $warmgrey10,
	warmgrey20: $warmgrey20,
	warmgrey30: $warmgrey30,
	warmgrey40: $warmgrey40,
	warmgrey50: $warmgrey50,
	warmgrey60: $warmgrey60,
	warmgrey70: $warmgrey70,
	warmgrey80: $warmgrey80,
	warmgrey90: $warmgrey90,

	grey: $grey,
	bluegrey: $bluegrey,
	lightbluegrey: $lightbluegrey,
	mediumbluegrey: $mediumbluegrey,
	bluegrey10: $bluegrey10,
	bluegrey20: $bluegrey20,
	bluegrey30: $bluegrey30,
	bluegrey40: $bluegrey40,
	bluegrey50: $bluegrey50,
	bluegrey60: $bluegrey60,
	bluegrey70: $bluegrey70,
	bluegrey80: $bluegrey80,
	bluegrey90: $bluegrey90,

	lightgrey: $lightgrey,
	mediumgrey: $mediumgrey,

	lighterblue: $lighterblue,
	lightblue: $lightblue,
	blue: $blue,
	mediumblue: $mediumblue,
	darkblue: $darkblue,
	darkerblue: $darkerblue,
	spiritblue: $spiritblue,

	bluelight: $bluelight,
	bluedark: $bluedark,
	greylight: $greylight,
	greymedium: $greymedium,

	teal: $teal,
	tealdark: $tealdark,
	darkteal: $tealdark,
	pink: $pink,
	pinklightest: $pinklightest,
	pinklighter: $pinklighter,
	pinkdarker: $pinkdarker,
	pinkdarkest: $pinkdarkest,
	green: $green,
	greenlightest: $greenlightest,
	greenlighter: $greenlighter,
	greendarker: $greendarker,
	greendarkest: $greendarkest,
	yellow: $yellow,
	yellowlightest: $yellowlightest,
	yellowlighter: $yellowlighter,
	yellowdarker: $yellowdarker,
	yellowdarkest: $yellowdarkest,
	purple: $purple,
	purplelightest: $purplelightest,
	purplelighter: $purplelighter,
	purpledarker: $purpledarker,
	purpledarkest: $purpledarkest,
	orange: $orange,
	darkorange: $darkorange,

	print_pink: $print_pink,
	print_pinklightest: $print_pinklightest,
	print_pinklighter: $print_pinklighter,
	print_pinkdarker: $print_pinkdarker,
	print_pinkdarkest: $print_pinkdarkest,
	print_green: $print_green,
	print_greenlightest: $print_greenlightest,
	print_greenlighter: $print_greenlighter,
	print_greendarker: $print_greendarker,
	print_greendarkest: $print_greendarkest,
	print_yellow: $print_yellow,
	print_yellowlightest: $print_yellowlightest,
	print_yellowlighter: $print_yellowlighter,
	print_yellowdarker: $print_yellowdarker,
	print_yellowdarkest: $print_yellowdarkest,
	print_purple: $print_purple,
	print_purplelightest: $print_purplelightest,
	print_purplelighter: $print_purplelighter,
	print_purpledarker: $print_purpledarker,
	print_purpledarkest: $print_purpledarkest,

	fbit: $fbit,
	fed: $fed,
	fesns: $fesns,
	feas: $feas,
	fhs: $fhs,
	fsci: $fsci,
	fssh: $fssh,
	grad: $grad,

	white: $white,
	black: $black,
	success: $success,
	warning: $warning,
	alert: $alert,
	red: $red,
	emergency: $emergency,

	btfgreen: $btfgreen,
	btfpink: $btfpink,
	btfyellow: $btfyellow,
	btfpurple: $btfpurple,
	btfindigo: $btfindigo,
	btflightblue: $btflightblue,
	btfgrey: $btfgrey,

	dcgreen: $dcgreen,
	uoitdcgrey: $uoitdcgrey,

	btn_lightblue: $lightblue,
	btn_darkblue: $darkblue,
	btn_grey: $grey,
	btn_greylight: $lightgrey,
	btn_orange: $orange,
	btn_pink: $pink,
	btn_green: $green,
	greydark: $mediumgrey,
	uoitpink: $pink,
	uoitorange: $yellow,
	uoitgreen: $green,
	uoitlightblue: $lightblue,
	uoitdarkblue: $darkblue,
	uoitgrey: $grey
);

$body-background: $white;
$body-font-color: $black;
$body-font-family: $font-body; // 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.5rem 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$print-transparent-backgrounds: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
	xxsmall: 0,
	xsmall: 500px,
	small: 800px,
	medium: 1000px,
	large: 1200px,
	xlarge: 1400px,
);
$breakpoint-classes: (xxsmall xsmall small medium large xlarge);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
	xxsmall: 30px
) !default;
// $grid-column-gutter: (
// 	xxsmall: 30px,
// 	xsmall: 30px,
// 	small: 30px,
// 	medium: 30px,
// 	large: 30px,
// 	xlarge: 30px,
// ) !default;
$grid-column-align-edge: true;
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: $font-heading; //$body-font-family;
$header-font-weight: 900; //$global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-styles: (
  xxsmall: (
    'h1': ('font-size': 24),
    'h2': ('font-size': 20),
    'h3': ('font-size': 19),
    'h4': ('font-size': 18),
    'h5': ('font-size': 17),
    'h6': ('font-size': 16),
  ),
  xsmall: (
    'h1': ('font-size': 30),
    'h2': ('font-size': 26),
    'h3': ('font-size': 22),
    'h4': ('font-size': 20),
    'h5': ('font-size': 18),
    'h6': ('font-size': 16),
  ),
  small: (
    'h1': ('font-size': 36),
    'h2': ('font-size': 32),
    'h3': ('font-size': 28),
    'h4': ('font-size': 24),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
  medium: (
    'h1': ('font-size': 36),
    'h2': ('font-size': 32),
    'h3': ('font-size': 28),
    'h4': ('font-size': 24),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
  large: (
    'h1': ('font-size': 38),
    'h2': ('font-size': 32),
    'h3': ('font-size': 28),
    'h4': ('font-size': 24),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
  xlarge: (
    'h1': ('font-size': 38),
    'h2': ('font-size': 32),
    'h3': ('font-size': 28),
    'h4': ('font-size': 24),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16),
  ),
);

$header-color: $darkblue; //inherit;
$header-lineheight: 1.1; // 1.4;
$header-margin-bottom: .75rem; //0.5rem;
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.4;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: #fff; //$black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: #272822; //$black;//rgba($lightblue, .1);//$light-gray;
$code-border: 0px solid $medium-gray;
$code-padding: .15rem .35rem; //rem-calc(2 5 1);
$anchor-color: $lightblue;
$anchor-color-hover: scale-color($anchor-color, $lightness: -20%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $darkblue;
$blockquote-padding: 1rem;
$blockquote-border: 0px solid $orange;
$cite-font-size: 1rem;
$cite-color: $darkblue;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $grey; //$dark-gray; 
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;

// 6. Abide
// --------

$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: get-color(alert);
$form-label-color-invalid: get-color(alert);
$input-error-color: get-color(alert);
$input-error-font-size: rem-calc(12);
$input-error-font-weight: $global-weight-bold;

// 7. Accordion
// ------------

$accordion-background: $white;
$accordion-plusminus: false;
$accordion-title-font-size: rem-calc(16);
$accordion-item-color: $primary-color;
$accordion-item-background-hover: get-color(mediumblue);
$accordion-item-padding: .75rem 2.5rem .75rem 1rem;
$accordion-content-background: $white;
$accordion-content-border: 1px solid get-color(greymedium);
$accordion-content-color: $body-font-color;
$accordion-content-padding: 1rem;

// 8. Accordion Menu
// -----------------

$accordionmenu-arrows: true;
$accordionmenu-arrow-color: $primary-color;
$accordionmenu-arrow-size: 6px;

// 9. Badge
// --------

$badge-background: $primary-color;
$badge-color: $white;
$badge-color-alt: $black;
$badge-palette: $foundation-palette;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 3px 0;// use_gutter() 0 3px 0;
$breadcrumbs-item-font-size: rem-calc(14);
$breadcrumbs-item-color: $darkblue; //$white;
$breadcrumbs-item-color-current: $darkblue;//$white;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-slash: true;

// 11. Button
// ----------

$button-padding: .8em 1em; //0.85em 1.25em; 
$button-lr-padding: .8em;
$button-tb-padding: 1.25em;
$button-margin: 0 0 4px 0; //0 0 $global-margin 0;
$button-fill: solid;
$button-background: $secondary-color;
$button-background-hover: smart-scale($button-background, 10%); //scale-color($button-background, $lightness: -25%);
$button-color: $white; //smart-scale($button-background, 10%);//
$button-color-alt: $black;
$button-radius: $global-radius;
$button-sizes: (tiny: rem-calc(10),
small: rem-calc(12),
default: rem-calc(15),
large: rem-calc(20),
);
// $button-sizes: (
//   tiny: 0.6rem,
//   small: 0.75rem,
//   default: 0.9rem,
//   large: 1.25rem,
// );
$button-palette: $foundation-palette;
$button-opacity-disabled: .5;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -40%;
$button-hollow-background-color: rgba(255, 255, 255, .8); // custom variable; used in Fndtn @mixin button-hollow
$button-transition: background-color 0.25s ease-out,
color 0.25s ease-out;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;
$buttongroup-radius-on-each: true;

// 13. Callout
// -----------

// $callout-background: $white;
$callout-background-fade: 85%;
$callout-border: 1px solid rgba($black, 0.25);
$callout-margin: 0 0 1rem 0;
$callout-padding: 1rem;
$callout-font-color: $body-font-color;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;
$callout-link-tint: 30%;



// 14. Close Button
// ----------------

$closebutton-position: right top;
$closebutton-offset-horizontal: (small: 0.66rem,
medium: 1rem,
);
$closebutton-offset-vertical: (small: 0.33em,
medium: 0.5rem,
);
$closebutton-size: (small: 1.5em,
medium: 2em,
);
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;

// 15. Drilldown
// -------------

$drilldown-transition: transform 0.25s ease;
$drilldown-arrows: false;
$drilldown-padding: $global-menu-padding;
$drilldown-arrow-color: $accent;
$drilldown-arrow-size: 6px;
$drilldown-background: $white;

// 16. Dropdown
// ------------

$dropdown-padding: 1rem;
$dropdown-border: 1px solid $medium-gray;
$dropdown-font-size: 1rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (tiny: 100px,
small: 200px,
large: 400px,
);

// 17. Dropdown Menu
// -----------------

$dropdownmenu-arrows: true;
$dropdownmenu-arrow-color: $anchor-color;
$dropdownmenu-arrow-size: 6px;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: $white;
$dropdownmenu-border: 1px solid $medium-gray;

// 18. Flex Video
// --------------

$flexvideo-margin-bottom: rem-calc(16);
$flexvideo-ratio: 4 by 3;
$flexvideo-ratio-widescreen: 16 by 9;

// 19. Forms
// ---------

$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$helptext-color: $black;
$helptext-font-size: rem-calc(15);
$helptext-font-style: italic;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;
$form-label-color: $darkblue; //#4d4d4d;
$form-label-font-size: rem-calc(16);
$form-label-font-weight: $global-weight-bold;
$form-label-line-height: 1.5;
$select-background: $white;
$select-triangle-color: $dark-gray;
$select-radius: $global-radius;
$input-color: $black;
$input-placeholder-color: $medium-gray;
$input-font-family: inherit;
$input-font-size: rem-calc(17);
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid $medium-gray;
$input-border-focus: 1px solid $dark-gray;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: default;
$input-transition: box-shadow 0.5s,
border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;
$form-button-radius: $global-radius;

// 20. Label
// ---------

$label-background: $primary-color;
$label-color: $white;
$label-color-alt: $black;
$label-palette: $foundation-palette;
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;

// 21. Media Object
// ----------------

$mediaobject-margin-bottom: $global-margin;
$mediaobject-section-padding: $global-padding;
$mediaobject-image-width-stacked: 100%;

// 22. Menu
// --------

$menu-margin: 0;
$menu-margin-nested: 1rem;
$menu-item-padding: 0.5rem 1rem;
$menu-item-color-active: $lightblue !default; 
$menu-item-background-active: $mediumgrey !default; 
$menu-icon-spacing: 0.25rem;
$menu-item-background-hover: $mediumgrey;
$menu-border: $light-gray;

// 23. Meter
// ---------

$meter-height: 1rem;
$meter-radius: $global-radius;
$meter-background: $medium-gray;
$meter-fill-good: $success-color;
$meter-fill-medium: $warning-color;
$meter-fill-bad: $alert-color;

// 24. Off-canvas
// --------------

$offcanvas-sizes: (small: 250px);
$offcanvas-vertical-sizes: (small: 250px);
$offcanvas-background: $white; // $light-gray;
$offcanvas-shadow: none; //0 0 10px rgba($black, 0.7);
$offcanvas-push-zindex: 11;
$offcanvas-overlap-zindex: 11;
$offcanvas-reveal-zindex: 11;
$offcanvas-overlay-zindex: 10; // content overlay
// $offcanvas-zindex: -1;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba(0, 0, 0, .2); // rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';
// $maincontent-shadow: none;// 0 0 10px rgba($black, 0.5);

// 25. Orbit
// ---------

$orbit-bullet-background: $medium-gray;
$orbit-bullet-background-active: $dark-gray;
$orbit-bullet-diameter: 1.2rem;
$orbit-bullet-margin: 0.1rem;
$orbit-bullet-margin-top: 0.8rem;
$orbit-bullet-margin-bottom: 0.8rem;
$orbit-caption-background: rgba($black, 0.5);
$orbit-caption-padding: 1rem;
$orbit-control-background-hover: rgba($black, 0.5);
$orbit-control-padding: 1rem;
$orbit-control-zindex: 10;

// 26. Pagination
// --------------

$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: $black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $light-gray;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: $white;
$pagination-item-color-disabled: $medium-gray;
$pagination-ellipsis-color: $black;
$pagination-mobile-items: false;
$pagination-mobile-current-item: false;
$pagination-arrows: true;

// 27. Progress Bar
// ----------------

$progress-height: 1rem;
$progress-background: $medium-gray;
$progress-margin-bottom: $global-margin;
$progress-meter-background: $primary-color;
$progress-radius: $global-radius;

// 28. Reveal
// ----------

$reveal-background: $white;
$reveal-width: 600px;
$reveal-max-width: $global-width;
$reveal-padding: $global-padding;
$reveal-border: 1px solid $medium-gray;
$reveal-radius: $global-radius;
$reveal-zindex: 1005;
$reveal-overlay-background: rgba($black, 0.45);

// 29. Slider
// ----------

$slider-width-vertical: 0.5rem;
$slider-transition: all 0.2s ease-in-out;
$slider-height: 0.5rem;
$slider-background: $light-gray;
$slider-fill-background: $medium-gray;
$slider-handle-height: 1.4rem;
$slider-handle-width: 1.4rem;
$slider-handle-background: $primary-color;
$slider-opacity-disabled: 0.25;
$slider-radius: $global-radius;

// 30. Switch
// ----------

$switch-background: $medium-gray;
$switch-background-active: $primary-color;
$switch-height: 2rem;
$switch-height-tiny: 1.5rem;
$switch-height-small: 1.75rem;
$switch-height-large: 2.5rem;
$switch-radius: $global-radius;
$switch-margin: $global-margin;
$switch-paddle-background: $white;
$switch-paddle-offset: 0.25rem;
$switch-paddle-radius: $global-radius;
$switch-paddle-transition: all 0.25s ease-out;

// 31. Table
// ---------

$table-background: $white;
$table-color-scale: 5%;
$table-border: 1px solid $coolgrey80;
$table-padding: rem-calc(8 10 10);
$table-hover-scale: 3%;
$table-row-hover: $bluegrey30;
$table-row-stripe-hover: $bluegrey50;
$table-is-striped: true;
$table-striped-background: $coolgrey20;
$table-stripe: even;
$table-head-background: $bluegrey;
$table-head-row-hover: $bluegrey90;
$table-foot-background: $bluegrey40;
$table-foot-row-hover: $bluegrey60;
$table-head-font-color: $white;
$show-header-for-stacked: false;

// 32. Tabs
// -------- 

$tab-margin: 0;
$tab-background: $white;
$tab-background-active: get-color(darkblue);
$tab-active-color: $white;
$tab-item-font-size: rem-calc(16);
$tab-item-background-hover: $bluegrey40;
$tab-item-padding: .75rem 1rem;
$tab-expand-max: 6;
$tab-content-background: $white;
$tab-content-border: get-color(mediumgrey);
$tab-content-color: $body-font-color;
$tab-content-padding: 1rem;

// 33. Thumbnail 
// -------------

$thumbnail-border: solid 4px $white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

// 34. Title Bar
// -------------

$titlebar-background: $black;
$titlebar-color: $white;
$titlebar-padding: 0.5rem;
$titlebar-text-font-weight: bold;
$titlebar-icon-color: $white;
$titlebar-icon-color-hover: $medium-gray;
$titlebar-icon-spacing: 0.25rem;

// 35. Tooltip
// -----------

$has-tip-font-weight: $global-weight-bold;
$has-tip-border-bottom: dotted 1px $dark-gray;
$tooltip-background-color: $black;
$tooltip-color: $white;
$tooltip-padding: 0.75rem;
$tooltip-font-size: $small-font-size;
$tooltip-pip-width: 0.75rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-radius: $global-radius;

// 36. Top Bar
// -----------

$topbar-padding: 0.5rem;
$topbar-background: $white;
$topbar-submenu-background: $topbar-background;
$topbar-title-spacing: 1rem;
$topbar-input-width: 200px;
$topbar-unstack-breakpoint: medium;

// 37. Card
// --------

$card-background: $white;
$card-font-color: $body-font-color;
$card-divider-background: $light-gray;
$card-border: 1px solid $light-gray;
$card-shadow: none;
$card-border-radius: $global-radius;
$card-padding: $global-padding;
$card-margin: $global-margin;

// 38. Responsive Embed
// --------------------

$responsive-embed-margin-bottom: rem-calc(16);
$responsive-embed-ratios: (
	default: 4 by 3,
	widescreen: 16 by 9,
	banner16x5: 16 by 5, //1920x600
	banner1920x600: 16 by 5,
	banner1920x650: 14.75 by 5,
	thumbnail3x2: 3 by 2
);