@debug "lightgallery-settings";

$lg-toolbar-bg: transparent;

// Basic icon colours
$lg-icon-bg: transparent;
$lg-icon-color: #fff;
$lg-icon-hover-color: $primary-color;

// Sub html - caption
$lg-sub-html-bg: transparent;
$lg-sub-html-color: #fff;

$zindex-controls: 1081; 

