/* - - - - - - - - - - - - - - - - - - - - - - - - -
  
  Related files: 
  - components/containers/feature-module

  TABLE OF CONTENTS
  
  1.  Spotlight
  2.  Spotlight - video 

- - - - - - - - - - - - - - - - - - - - - - - - - */


// 1. Spotlight
// - - - - - - - - - - - - - - - - - - - - - - - - -

.spotlight {
  @include transition(width $transition-duration linear);
  @include row_gutter();
  @include breakpoint(xsmall down) {
    @include flex-grid-column(12);
  }
  
  .content {
    @include breakpoint(small) {
      max-height: $max_module_height;// + 70px; 
      overflow-y: auto;
    }
	} 
	
	.feature-module .content {
		max-height: none;
	}

  h3, h4 {
    @include typography-primary-link($accent-color, $accent-color, 1.2rem);
    margin-bottom: 0;
  }

  p {
    line-height: normal;
  }  
  
} // .spotlight



// 2. Spotlight - video
// - - - - - - - - - - - - - - - - - - - - - - - - -

.spotlight_video {
  @extend .spotlight;
  
  figcaption {
    font-family: $font-body;
    font-weight: normal;
  }

  p:last-child:not(first-child) {
    margin-bottom: 0;
  }


  // Float video for 2/3 and full (large) width container 
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  &.small-8,
  &.small-12 {
    @include breakpoint(small only) {
      figure {
        @include grid-column(6);
      }
      figcaption {
        @include grid-column(6);
        position: absolute;
        top:0;
        right:0;     
      }
    }
  }
  
  &.medium-12 {
    @include breakpoint(medium) { 
      figure {
        @include grid-column(6);
      }
      figcaption {
        @include grid-column(6);
        position: absolute;
        top:0;
        right:0;
      }
    }
  }
  
  &.medium-8,
  &:not([class*="medium-"]) {
    @include breakpoint(medium) { 
      figure {
        @include grid-column(8);
      }
      figcaption {
        @include grid-column(4);
        position: absolute;
        top:0;
        right:0;
      }
    }
  }
  

} // .spotlight_video