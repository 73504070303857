.off-canvas-content {
	@include breakpoint(medium) {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
}

#main-content {
	@include breakpoint(medium) {
		position: relative;
		// border-top: 1px solid #fff;
		// allow #main-content to push footer and .page-row to fill content
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}

.page-row {
	@include grid-row();
	display: block;
	position: relative;
	// background: #fff;
	// allow .page-row to fill its flex container's height
	flex: 1 0 auto;
	width: 100%;
	z-index: 1;

	// Legacy support for "page-row row" class
	&.row {
		.page-content {
			padding-left: use_gutter();
			padding-right: use_gutter();

			@include breakpoint(xxsmall only) {
				padding-left: use_gutter();
				padding-right: use_gutter();
			}
		}
	}
}
.banner + .page-row {
	@include breakpoint(medium down) {
		margin-top: use_gutter();
	}

	// on (home) pages with no breadcrumbs, 
	// remove the top margin in the page content
	.page-content:not(.has-sidebar) {
		margin-top: 0;
	}
}

// Standard page content (within the grid)
.page-content {
	@include flex-grid-column(12);
	margin-top: use_gutter();
	margin-bottom: use_gutter();
	padding-top: use_gutter(.5);
	padding-bottom: use_gutter(.5);

	@include breakpoint(xxsmall only) {
		padding-left: 0;
		padding-right: 0;
	}

	&.has-sidebar {
		@include breakpoint(medium up) {
			@include grid-column(9); //, use_gutter(2, large));		
			@include grid-column-position(3);
			border-left: 1px solid map-get($foundation-palette, greymedium);
		}
	}

	>.row>.columns,
	>.row>.column {
		@include breakpoint(xxsmall down) {
			min-width: 100%;
			flex: 1 1 auto;
		}

		@include breakpoint(small) {
			min-width: rem-calc(200);
		}
	}
}

// Landing page content (full width)
.breadcrumbs + .landing-page-content {
	margin-top: use_gutter();
	z-index: 1;

	> .row:not(.expanded) {
		padding-left: use_gutter(.5);
		padding-right: use_gutter(.5);
	}
}


.card-grid>.columns {
	@include breakpoint(xsmall down) {
		min-width: 100%;
		flex: 1 1 auto;
	}

	@include breakpoint(small) {
		min-width: rem-calc(200);
	}
}