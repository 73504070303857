/** 
 * See also: 
 * /util/animations (Dan Eden)
 * /util/brand-elements
 */
 
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes flip-in-x {
  from { transform: rotateX(90deg); }
  to { transform: rotate(0); }
}

@keyframes flip-in-y {
  from { transform: rotateY(90deg); }
  to { transform: rotate(0); }
}

@keyframes slide-in-down {
	from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
		opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
		opacity: 1;
  }
}

@keyframes slide-in-up {
  from { bottom: -100%; opacity: 0; }
}

@keyframes slide-up-y {
  from { bottom: 0; opacity: 0; }
}

