$lightblue:		#0077ca; 
$darkblue: 		#003c71;
$orange:		  #e75d2a;
$darkorange: 	#CF4817; // AA compliant for regular text
// $grey:				#53565a;

// 50 shades of blue
$spiritblue: #00283C;
$darkerblue: scale-color($darkblue, $lightness: -30%, $saturation: -10%);
$blue: scale-color($lightblue, $lightness: -27%);
$lighterblue: scale-color($lightblue, $lightness: 18%, $saturation: -30%);

// 50 shades of grey
$coolgrey: #A7A8AA; // Pantone cool grey 6
$warmgrey: #ACA39A; // Pantone warm grey 5
$grey: #5B6770; // Pantone 431 C
$bluegrey: $grey;
// Default cool grey tints
$lightcoolgrey: scale-color($coolgrey, $lightness: 80%);
$mediumcoolgrey: scale-color($coolgrey, $lightness: 30%);
// % tints of cool grey
$coolgrey10: scale-color($coolgrey, $lightness: 90%);
$coolgrey20: scale-color($coolgrey, $lightness: 80%);
$coolgrey30: scale-color($coolgrey, $lightness: 70%);
$coolgrey40: scale-color($coolgrey, $lightness: 60%);
$coolgrey50: scale-color($coolgrey, $lightness: 50%);
$coolgrey60: scale-color($coolgrey, $lightness: 40%);
$coolgrey70: scale-color($coolgrey, $lightness: 30%);
$coolgrey80: scale-color($coolgrey, $lightness: 20%);
$coolgrey90: scale-color($coolgrey, $lightness: 10%);
// Default warm grey tints
$lightwarmgrey: scale-color($warmgrey, $lightness: 70%);
$mediumwarmgrey: scale-color($warmgrey, $lightness: 30%);
// % tints of warm grey
$warmgrey10: scale-color($warmgrey, $lightness: 90%);
$warmgrey20: scale-color($warmgrey, $lightness: 80%);
$warmgrey30: scale-color($warmgrey, $lightness: 70%);
$warmgrey40: scale-color($warmgrey, $lightness: 60%);
$warmgrey50: scale-color($warmgrey, $lightness: 50%);
$warmgrey60: scale-color($warmgrey, $lightness: 40%);
$warmgrey70: scale-color($warmgrey, $lightness: 30%);
$warmgrey80: scale-color($warmgrey, $lightness: 20%);
$warmgrey90: scale-color($warmgrey, $lightness: 10%);
// Default shades of blue-grey
$lightbluegrey: scale-color($bluegrey, $lightness: 60%);
$mediumbluegrey: scale-color($bluegrey, $lightness: 20%);
// % tints of blue grey
$bluegrey10: scale-color($bluegrey, $lightness: 90%);
$bluegrey20: scale-color($bluegrey, $lightness: 80%);
$bluegrey30: scale-color($bluegrey, $lightness: 70%);
$bluegrey40: scale-color($bluegrey, $lightness: 60%);
$bluegrey50: scale-color($bluegrey, $lightness: 50%);
$bluegrey60: scale-color($bluegrey, $lightness: 40%);
$bluegrey70: scale-color($bluegrey, $lightness: 30%);
$bluegrey80: scale-color($bluegrey, $lightness: 20%);
$bluegrey90: scale-color($bluegrey, $lightness: 10%);

// Former grey shades are now tints of cool grey/blue-grey
$lightgrey: $lightcoolgrey; //scale-color($grey, $lightness: 90%);
$mediumgrey: $mediumcoolgrey; //scale-color($grey, $lightness: 60%);

$official-palette: (
	lightblue: $lightblue,
	darkblue: $darkblue,
	lighterblue: $lighterblue,
	blue: $blue,
	mediumblue: $blue,
	darkerblue: $darkerblue,
	spiritblue: $spiritblue,
	orange: $orange,
	darkorange: $darkorange,
	accent: $orange,
	darkaccent: $darkorange,
	grey: $grey,
	lightgrey: $lightgrey,
	mediumgrey: $mediumgrey,
	coolgrey: $coolgrey,
	lightcoolgrey: $lightcoolgrey,
	mediumcoolgrey: $mediumcoolgrey,
	warmgrey: $warmgrey,
	bluegrey: $bluegrey,
	white: #ffffff
);


// Variable variants
$bluelight: $lightblue;
$bluedark: $darkblue;
$mediumblue: $blue;
$greylight: $lightgrey;
$greymedium: $mediumgrey;

// Faculty
$fbit: 	#00558C;
$fed: 	#4F758B;
$fesns: #6F263D;
$feas: 	#5F259F;
$fhs: 	#AF272F;
$fsci: 	#007749;
$fssh: 	#BE4D00;
$grad: 	#000000;

// Misc UI colours
$red: 			#D00606;
$emergency: #A40B0B;
$success: 	#4E8200;
$warning: 	#FFAE00;
$alert: 		#D00606;

// Brave the Future campaign 
$btfgreen:			#84BD00;
$btfpink:				#C71566;
$btfyellow:			#FFCD3A;
$btfpurple:			#804693;
$btfindigo:			#211551;
$btflightblue:	#41B6E6;
$btfgrey:				#333F48;

// UOIT-DC / DC
$uoitdcgrey:	#414042;
$dcgreen:			#037C5A;



// Deprecated colours, legacy variables
$teal:				$lightblue; //#009a88;//#1B8376;
$darkteal:		$lightblue; //#135D53;
$tealdark:		$lightblue; //$darkteal;

// Secondary - web
$green: 					$lightblue; // #4E8200;
$greenlightest:		$lightblue; // #d4eb8e;//#AEE55C;
$greenlighter: 		$lightblue; // #b1c58c;//#82C223;
$greendarker: 		$lightblue; // #3A6100;
$greendarkest:		$lightblue; // #233B00;//#233B00;
$pink: 						$lightblue; // #C71566;
$pinklightest:		$lightblue; // #FFA8CF;
$pinklighter:			$lightblue; // #FF70B0;
$pinkdarker: 			$lightblue; // #A31253;
$pinkdarkest: 		$lightblue; // #630B33;
$yellow:					$lightblue; // #FFC717;//#D98900; 
$yellowlightest:	$lightblue; // #ffe570;//#FFB83D;
$yellowlighter:	 	$lightblue; // #FDDA3C;//#FFA817;
$yellowdarker: 		$lightblue; // #A66800;
$yellowdarkest:		$lightblue; // #7F4E28;//#7F5000;
$purple: 					$lightblue; // #804693;//#814794;
$purplelightest:	$lightblue; // #D0ADD6;//#E6B3F5;
$purplelighter: 	$lightblue; // #BE86CF;
$purpledarker: 		$lightblue; // #61326D;//#683A78;
$purpledarkest: 	$lightblue; // #521C4E;//#573063; 

// Secondary - print
$print_green: 					$lightblue; // #84bd00;
$print_greenlightest:		$lightblue; // #d4eb8e;
$print_greenlighter: 		$lightblue; // #b1c58c;
$print_greendarker: 		$lightblue; // #799748;
$print_greendarkest:		$lightblue; // #5A5F32;
$print_pink: 						$lightblue; // #C71566;
$print_pinklightest:		$lightblue; // #F5A0C2;
$print_pinklighter:			$lightblue; // #F280AF;
$print_pinkdarker: 			$lightblue; // #C22060;
$print_pinkdarkest: 		$lightblue; // #8A1845;
$print_yellow:					$lightblue; // #FFCD3A;
$print_yellowlightest:	$lightblue; // #FFE057;
$print_yellowlighter:	 	$lightblue; // #FFDC42;
$print_yellowdarker: 		$lightblue; // #DDA132;
$print_yellowdarkest:		$lightblue; // #7F4E28;
$print_purple: 					$lightblue; // #804693;
$print_purplelightest:	$lightblue; // #BFA8CD;
$print_purplelighter: 	$lightblue; // #B283B6;
$print_purpledarker: 		$lightblue; // #773A7C;
$print_purpledarkest: 	$lightblue; // #612C64; 

// 2015 Secondary colours
// $orange: $yellowdarker;

// Legacy variables
$uoitlightblue: $lightblue;
$uoitdarkblue:	$darkblue;
$uoitgrey: 			$grey;
$uoitgreylight: scale-color($grey, $lightness: 90%); //95 
$uoitgreymedium:scale-color($grey, $lightness: 75%); //90 
$uoitgreydark: 	scale-color($grey, $lightness: 75%); //80
$uoitpink: 			$pink;
$uoitgreen: 		$green;
$uoitorange: 		$orange;
$lightgrey: 		$greylight;
$mediumgrey:		$greymedium;