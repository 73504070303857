/**
 * 1. Tab titles
 * 2. Tab content
 * 3. Vertical Tabs
 * 4. Nested Tabs/Accordions
 */


// 1. Tab titles
// -------------

.tabs {
	margin-bottom: 0;
	border: 0;
	border-bottom: 2px solid $darkblue;

	// Standard tabs (not responsive-accordion-tabs)
	&[data-tabs] {
		@include breakpoint(small down) {
			@include tabs-container-vertical;

			.tabs-title {
				border-top: 1px solid $tab-content-border;
			}
		}
	}
}
.tabs-title {
	margin-bottom: 0 !important;
	background: $bluegrey20; //rgba($darkblue, .15);

	>a {
		font-family: $font-heading;
		font-weight: bold;
		line-height: 1.1;
		color: darken($bluegrey,5%);
		outline: none;
	}

	@include breakpoint(medium) {
		margin-right: 3px;
	}
}


// 2. Tabs content
// ---------------

.tabs-content {
	border: 0;
	margin-bottom: $global-margin;

	.page-content & {
		@include clear; 
	}
}


// 3. Vertical tabs
// ----------------

.vertical-tabs {
	@include breakpoint(medium) {
		display: flex;
		flex-flow: row wrap;
		margin-bottom: 1rem;

		> .tabs {
			min-width: 15%;
			max-width: 20%;
			border-right: 1px solid $darkblue;
			border-bottom: 0;
		}

		> .tabs-content {
			flex: 1 1 0;
		}

		.tabs-title a {
			padding: 1rem !important;
		}
	}
}

.tabs.vertical {
	.tabs-title {
		margin-right: 0;
	}
}

.tabs-content.vertical,
.vertical-tabs > .tabs-content {
	border: 0;
}


// 4. Nested tabs/accordions
// -------------------------

.tabs-content { 
	// Tabs
	// ----
	.tabs {
		background: $bluegrey10;
		box-shadow: inset 0 -1px 0 $tab-content-border;
		border-bottom: 0;
		margin: -1rem -1rem 0 -1rem;
	}
	.tabs-title {
		margin-right: 0;
		background: transparent;

		> a {
			padding: .65rem 1rem;
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;
		}

		@include breakpoint(small down) {
			&.is-active a,
			a:focus {
				background: $grey;
			}
		}
		
		@include breakpoint(medium) {
			&.is-active {
				> a {
					border-left-color: $tab-content-border;
					border-right-color: $tab-content-border;
				}
				&:first-child > a {
					border-left-color: transparent;
				}
			}
			&.is-active a {
				background: $white;
				color: $darkblue;
			}	
			a:active {
				background: $darkblue;
				color: $white;
			}
		}
	}
	.tabs-content {
		margin: 0 -1rem;
	}

	// Accordions
	// ----------
	.accordion-title {
		padding: .65rem 1rem;
	}

	@include breakpoint(small down) {
		.accordion-title {
			box-shadow: inset 3px 0 0 $bluegrey60;
		}
		.is-active > .accordion-title,
		.accordion-title:hover,
		.accordion-title:focus {
			background: $bluegrey50;
			color: $darkblue;
		}
	}
}

// Vertical tabs with nested tabs
// ------------------------------
.tabs-content.vertical,
.vertical-tabs > .tabs-content {

	@include breakpoint(medium) {
		.tabs-title {
			>a {
				border-top: 1px solid $lightgrey;
			}
			&.is-active > a {
				border-top-color: $tab-content-border;
			}
		}
	}
}
