/**
 *  
 *  Related files: 
 *  - components/containers/feature-module
 *
 *  TABLE OF CONTENTS
 *  
 *  1.  Spotlight
 *	2.	Spotlight - background (feature) image
 *  3.  Spotlight - figure/figcaption 
 *
 */

// 1. Spotlight
// ------------

.spotlight.top-margin,
.spotlight.margin-top {
	@include feature-section-margins(top);
}
.spotlight.bottom-margin,
.spotlight.margin-bottom {
	@include feature-section-margins(bottom);
}

.spotlight {
	z-index: 1;
	
	// Specified background colour and associated text colours
	@include spotlight-colors;

	// Link colours
	@each $color,
	$value in $official-palette {
		// &.#{$color} .content {
		&.#{$color} {
			*:not(a) {
				color: color-pick-contrast($value, ($white, $darkblue));
			}

			a:not([class]) { 
				@include link-style(color-pick-contrast($value, ($white, $anchor-color)));
			}
		}
	}

	// Remove top margin if immediately after .cta-thumbnails
	@at-root {
		.cta-thumbnails + .spotlight {
			margin-top: 0;
		}
	}

	&.column .feature-module {
		@include breakpoint(large) {
			padding: rem-calc(50) rem-calc(80); 
		}
	}
}


// 2. Spotlight - feature image
// ----------------------------

.spotlight.feature-image {
	position: relative;
	min-height: 500px;
	
	.feature-module {
		@extend .clear;
	}

	.feature-float-box {
		@include breakpoint(small) {
			&.align-left {
				float: left;
			}
			&.align-right {
				float: right;
			}
		}
		
		
		// Default colours
		background-color: $accent-color;
		@include breakpoint(small) {
			background: linear-gradient(-45deg,transparent 50px,rgba($accent-color, 0.9) 50px);
		}
		* {
			color: #fff;
		}
		
		// Specified background colour and associated text colours
		@each $color, $value in $official-palette {
			&.#{$color} {
				background-color: $value;
				@include breakpoint(small) {
					background: linear-gradient(-45deg,transparent 50px,rgba($value, 0.9) 50px);
				}

				* {
					color: color-pick-contrast($value, ($white, $darkblue));
				}

				.feature-subheading {
					border-bottom-color: rgba(color-pick-contrast($value, ($white, $darkblue)), .3);
				}

				.feature-link {
					@include feature-text-link-style(color-pick-contrast($value, ($white, $darkblue)), $accent-color);
				}
				// Override colors on orange box
				&.accent .feature-link,
				&.orange .feature-link {
					@include feature-text-link-style($white, $darkblue);
				}

			}
		}

    padding: 30px;
    font-family: $font-heading;
    font-size: 1.25rem;


		.feature-subheading {
			padding-bottom: 1rem;
			border-bottom: 1px solid rgba(#fff, .3);
			font-size: 1rem;
			text-transform: uppercase;
		}

		p:last-child {
			margin-bottom: 0;
		}

		.feature-link {
			@include feature-text-link-style($white, $darkblue);
		}
	}

	// Background image
	@include breakpoint(small) {
		.feature-image {		
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;

			img {
				display: none;
			}
		}
	}

	// Static image element
	@include breakpoint(xsmall down) {
		.feature-image img {
			display: block;
		}
		
		.feature-module {
			padding: 0;
		}
	}
}


// Spotlight - figure/figcaption
// -----------------------------

// Image/video in <figure>
@mixin figure-columns() {
	@include flex-grid-row;
	
	> * {
		@include flex-grid-column;
	}
	> :first-child {
		padding-left: 0;
	}
	> :last-child {
		padding-right: 0;
	}
}

// Image in <figure>
@mixin figure-columns-image() {
	img[class*='float-'] {
		float: none !important;
	}

	@include breakpoint(small down) {
		img {
			width: 100%;
		}
	}
	
	@include breakpoint(medium) {
		@include figure-columns;

		img {
			flex: 0 0 auto; // shrink to image width
			align-self: flex-start; // align to top by default
		}
	}
}

.landing-page-content,
.page-content {
	.spotlight.image,
	.spotlight.video,
	.spotlight.figure {
		@include breakpoint(small down) {
			[class*=column] {
				padding-left: 0;
				padding-right: 0;
			}
		}
		
		@include breakpoint(medium) {
			figure {
				@include figure-columns;
			}
		}
	}

	// Image 
	.spotlight:not(.figure),
	.spotlight:not(.video) {
		@extend .clear;
		
		// CMS-generated .image class
		figure.image {
			@include figure-columns-image;
		}
	}
	.spotlight.image {
		@extend .clear;

		figure {
			@include figure-columns-image;
		}
	}

	// Video
	.spotlight.video,
	.spotlight.figure {
		@include breakpoint(medium) {
			figcaption {
				max-width: 42%;
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				text-align: center;

				&.text-left {
					align-items: flex-start;
					text-align: left;
				}
			}
		}
	}
}

.page-sidebar .spotlight.figure {
	figure {
		[class*=column] {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
