/**
 *	Related files: 
 *	- components/containers/images

 *	Gallery grid (Masonry)
 *	Gallery row (Swiper)
 *  Pop up images (Lightgallery)
 */



// Gallery grid (Masonry - photo thumbnails)
// ------------

.gallery-grid,
.gallery_grid {
  // clear: both;
	
	// @include flex-grid-layout(5, '.grid-item');

  .grid-sizer,  
  .grid-item {  
    margin-bottom: 6px; 
    display: inline-block;
    // @include flex-grid-column(2);
	 	

    // width: 100%;  
    // // calculation: (100% - (gutter size * number of items-1)) / number of items)
    // @include breakpoint(xsmall) {
      width: calc((100% - (6px*1))/2); // 2 per row 
    // }
    @include breakpoint(small) {
      width: calc((100% - (6px*2))/3); // 3 per row 
    }
    @include breakpoint(medium) {
    //   width: calc((100% - (6px*3))/4); // 4 per row
    // } 
    // @include breakpoint(xlarge) {
      width: calc((100% - (6px*4))/5); // 5 per row
    } 
  }

  .grid-item img {
  	// width: 100%;
  } 
} 


// Gallery row (Swiper - photo thumbnails)
// -----------
@import "swiper/dist/css/swiper";

.gallery-row-container {
	position: relative;
	margin-bottom: 1rem;

	.gallery_row {
		height: 200px;
		width: 94%;
		margin: 0 3%;

		@include breakpoint(small down) {
			width: 90%;
			margin: 0 5%;
		}

		@include breakpoint(xxsmall only) {
			width: 100%; 
			margin: 0;
		}
	}
 
	.swiper-slide {
		width: auto;
		max-height: 100%;

		img {
			height: 100%;
			max-width: none;
		}
	}  

	.swiper-prev,
	.swiper-next {
		position: absolute;
    top: 0;
    left: 0;
    width: 3%; 
    height: 100%;
    display: block;
    padding: 0;
    margin: 0;
    background: scale-color($lightgrey, $lightness: -10%);
    font-size: rem-calc(28);
    @include transition(background $transition-duration ease-in-out); 

    @include breakpoint(small down) {
    	width: 5%;
    }

    @include breakpoint(xxsmall only) {
      position: static;
      float: left;
      width: 49%;
      height: rem-calc(40);
      margin: use_gutter(0.5) 0 0;
      font-size: rem-calc(26);
      line-height: rem-calc(40);
    }

    &::after {
      @include icon;
      content: get-icon(icon_arrow); 
      color: scale-color($lightgrey, $lightness: -30%);
    } 

    &:hover, &:active, &:focus {  
      background: scale-color($primary-color, $alpha: -25%);
    }
	}
	.swiper-next {
    left: auto;
    right: 0;

    @include breakpoint(xxsmall only) {
      float: right;
    }

    &::after {
      @include transform(rotate(180deg));
    }
  } 
}


// Lightgallery overrides 
// ----------------------
@import "components/containers/lightgallery-settings";
@import "lightgallery/src/sass/lightgallery";

.lg-sub-html {
	width: 100%;
	max-width: 1000px;
	left: 50% !important;
	transform: translateX(-50%);
	text-align: left !important;
	padding: 0 5% 40px !important;
	@include breakpoint(medium) {
		padding: 0 0 40px !important;
	}
}

.lg-outer {
  .lg-img-wrap {
    padding: 5%;
  }
} 

.lg-icon {
  font-family: $uoit-icon-font !important;
}
.lg-actions {
  .lg-next,
  .lg-prev {
		&:before {
			@include icon;
		}
  }
  .lg-next {
    // transform: rotate(180deg); 
    &:before {
      content: get-icon(icon_arrow_right);
    }
  } 
  .lg-prev {
    &:after {
      content: get-icon(icon_arrow_left);
    }
  }

  @include breakpoint(small down) {
    .lg-next, 
    .lg-prev {
      top: auto;
      bottom: 5px;
    }
    .lg-next {
      right: 5px;
    }
    .lg-prev {
      left: 5px;
    }
  }
}
.lg-toolbar {
  .lg-close {
    &:after {
			@include icon;
      content: get-icon(icon_cancel);
    }
  }
}
