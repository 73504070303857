// Links
// -----

a {
  @include transition(all $transition-duration ease);
}

// Default hover styles for text links
@mixin link-style($color: $lightblue, $hover: auto) {
  border-bottom: 1px dotted;
  background-image: linear-gradient(to right, transparent 50%, rgba($color, .1) 50%);
  background-position: 0;
	background-size: 200%;
	color: $color;

  &:hover, &:active, &:focus{
		background-position: -100%; 
		@if ($hover) {
			color: $hover;
		}
  }

  &:focus {
    outline-width: 1px;
  }
}

// Removes default hover styles for text links
@mixin remove-link-style() {
	border-bottom: none;
	background-image: none;
}

// Link with bold underline and chevron
@mixin feature-text-link($color: $lightblue) {
	@include typography-primary-link($color, $color, 1rem);
	display: inline-block;
	position: relative;
	padding-bottom: 3px;
	line-height: 1;

	// bottom border hover
	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 2px;
		transform: scaleX(0);
		transform-origin: right center;
		@include transition(transform 200ms ease-out);
	}

	// chevron
	&::after {
		content: '\203A';
		display: inline-block;
		font-size: 1.3rem;
		line-height: 0;
		margin-left: 5px;
		@include transition(color 200ms ease-out);
	}

	// hover effects
	&:hover,
	&:active,
	&:focus {
		&::before {
			transform: scaleX(1);
			transform-origin: left center;
		}
	} 
}
@mixin feature-text-link-style($color: $lightblue, $hover: $accent, $size: rem-calc(16)) {
	@include feature-text-link($color);
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: $color;
	font-size: $size;

	// bottom border hover
	&::before {		
		background: $hover;
	}
	// chevron
	&:hover,
	&:active,
	&:focus {
		&::after {
			color: $hover;
		}
	}
}

// e.g. top level navigation, buttons
@mixin typography-primary-link($color: $primary-color, $hover: $color, $size: .88rem) {
  font-family: $font-heading;
  font-weight: 900;
  font-size: $size;
  text-transform: uppercase;
  color: $color;

  &:hover, &:active, &:focus {
    color: $hover;
  }
}

@mixin typography-secondary-heading($color: $secondary-color) {
  font-family: $font-heading;
  font-weight: 900;
  font-size: 1.5rem;
  // text-transform: uppercase;
  color: $color;
}


.page-content,
#news-article,
.slider_content,
.site-docs {
  ul:not(.menu):not(.accordion):not(.tabs):not(.index-ctas):not(.quickLinks):not(.module-quick-links):not([class*="pagination"]) a,
  ol:not(.menu):not(.accordion):not(.tabs):not(.index-ctas):not(.quickLinks):not(.module-quick-links):not([class*="pagination"]) a,
  p a,
  table a,
	> a {
    &:not(.remove-link-style):not([class*="button"]):not([class*="btn"]):not([class*="accordion"]):not([class*="fc-"]):not(.feature-link) {
      // @extend %link-style; 
      @include link-style;
    }
  }
}

a.remove-link-style {
	@include remove-link-style();
}

a.feature-link {
	@include feature-text-link-style($color: $lightblue, $hover: $accent);
}

a.underline,
.underline a {
	border-bottom: 1px dotted;
}

button:focus {
  outline-width: 1px;
}


// to phase out
// ------------

@mixin text_nav_primary {
  font-family: $font-body;
  font-weight: 900;
  font-size: .88rem;
  text-transform: uppercase;
  color: $uoitgrey; 
}

// primary link style (text only)
@mixin link_primary($link_color, $hover_color) {
  //@extend %text_nav_primary;
    font-family: $font-body;
    font-weight: 900;
    font-size: .88rem;
    text-transform: uppercase;
    // extend not working?
  color: $link_color;
  @include transition(all 300ms ease-out);
  
  &:hover, &:active, &:focus {
    color: $hover_color; //scale-color($hover_color, $lightness: -20%);
  }
}

%text_nav_primary {
  font-family: $font-body;
  font-weight: 900;
  font-size: .88rem;
  text-transform: uppercase;
  color: $uoitgrey;
}


%secondary_nav_heading_links {
	font-family: $font-body;
  font-weight: 900;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: $secondary-color;
}

%secondary_nav_mobile_links {
	font-family: $font-body;
	font-size: .88rem;
	text-transform: none;
}