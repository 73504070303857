.events { 
	
	// 1. General layout
	// - - - - - - - - - - - - - - - - - - - - - - - - -
	
	@include transition(width $transition-duration linear);
	@include row_gutter();

	@include flex-grid-column(12);
	@include breakpoint(medium up) {
		@include flex-grid-column(4);
	}
 
	.content {
		// min-height: $max_module_height;
		@include breakpoint(medium) {
			max-height: $max_newsevents_height;
		}
	}

	#list_events { 
		overflow: hidden;
		width: 100%;  
		margin: 0;
		list-style: none;
		
		li { 
			@include breakpoint(xxsmall up) {
				&:not(:nth-child(-n+3)) {
					display: none;
				}
			}
			@include breakpoint(small only) {
				&:nth-child(-n+6) {
					@include grid-column(6);
				}
				&:nth-child(even) {
					padding-right: 0;
				}
				&:nth-child(odd) {
					padding-left: 0;
				}
				&:not(:nth-child(-n+3)) {
					display: block;
				}
				&:not(:nth-child(-n+6)) {
					display: none;
				}
			}
			@include breakpoint(medium up) {
				display: block !important;
			}
			
			margin-bottom: use_gutter(0.5);
			
			p {
				margin: 0;
				line-height: normal;
			}
			
			// 2. Text - date
			// - - - - - - - - - - - - - - - - - - - - - - - - -
			
			.event_date {
				float: left;
				padding: .75rem use_gutter(1);
				background: #fff;
				font-size: .88rem;
				text-align: center;
				text-transform: uppercase;
				//line-height: 1;
				@include transition(all .375s ease-in-out);
				
				span {
					display: block;
					font-size: 1.675rem;
				}
			} // .event_date
			

			// 3. Text - event info
			// - - - - - - - - - - - - - - - - - - - - - - - - -
			
			.event_info {
				padding: .75rem use_gutter(1);   
				background: #fff;
				font-size: .88rem;
				color: $body-font-color;
				
				* {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					@include transition(all .375s ease-in-out);
				}
				
				span {
					display: block;
				}
				
				.event_title {
					padding-left: use_gutter(1);
					font-family: $font-body;
					font-size: .9375rem;
					font-weight: 900;
				}
				.event_time {
					padding-left: use_gutter(1);
					font-size: .88em;
				}        
			} // .event_info
			

			// 4. Event link
			// - - - - - - - - - - - - - - - - - - - - - - - - -
			
			a {
				display: block;
				color: $primary-color;
			}
			
			a:hover, a:active, a:focus {
				cursor: pointer;

				.event_date {
					background: $primary-color;
					color: #fff;
				}
			}

		} // li
	} // #list_events
} // .events


// .news ~ .events {
//   .home-module .content {
//     @include breakpoint(medium up) {
//       background: blue;
//       min-height: $max_module_height;
//     }    
//   }
// }