/**
 *
 *  TABLE OF CONTENTS
 *     
 *  1. General layout
 *     - Image
 *     - Content box
 *     - Heading box
 *     - Body text box
 *  2. Slider box animation
 *  3. Controls 
 *  4. Pagination
 *
 */

 $swiperActive: ".swiper-slide-active"; 
 $swiperInactive: ".swiper-slide:not(.swiper-slide-active)";
 
 // 1. General layout
 // -----------------
 
 #banner-home,
 #slider-main.swiper-container,
 #banner_home, 
 #slider_main.swiper-container {
	 @include row_gutter();
	 width: 100%; 
 
	 @include breakpoint(small down) {
		 background: $primary-color;
	 }
	 @include breakpoint(xlarge) {
		 // max-height: 480px;
		 // overflow: hidden;
	 } 
	 
	 // Image
	 // -----
	 figure {
		 position: relative;
		 margin: 0;
	 
		 > a {
			 display: block; 
		 }
	 } 
	 .swiper-slide img {
		 width: 100%;
	 }
 
	 // Content box
	 // -----------
	 figcaption {
		 background-color: $primary-color; 
		 margin: 0;
 
		 @include breakpoint(medium up) {     
			 position: absolute;
			 top: 0; 
			 left: 0;
			 width: 100%;
			 background-color: transparent;
		 }  
 
		 &.slider-right,
		 &.slider_right {
			 @include breakpoint(medium) {
				 > div.row {
					 @include flex-align($x:right);
				 }  
			 }   
		 }
	 } 
	 .slider-box,
	 .slider_box {   
		 @include breakpoint(xxsmall) {
			 background: $primary-color;
		 }
		 @include breakpoint(medium) {
			 background: transparent;
			 @include grid-column($columns: 6);
		 } 
		 @include breakpoint(large) { 
			 @include grid-column($columns: 5);     
		 }
	 }
	 
	 // Heading box
	 // -----------
	 .slider-heading,
	 .slider_heading {   
		 padding: use_gutter() use_gutter() 0;
		 background: $primary-color;
		 
		 @include breakpoint(medium up) {
			 padding: use_gutter(2) use_gutter() use_gutter();
			 background: scale-color($primary-color, $alpha: -15%);
		 } 
		 
		 font-family: $font-heading;
		 color: #fff;
		 
		 h2 { 
			 margin: 0;
			 line-height: .9;
			 color: #fff;
		 }
	 }
	 .category {
		 @include serif;
	 } 
	 
	 // Body text box
	 // -------------
	 .slider-content,
	 .slider_content {
		 padding: use_gutter();
		 margin-top: 3px;
		 background: $primary-color;
		 
		 @include breakpoint(medium) {
			//  padding: use_gutter(1.5);
			 background: scale-color(#fff, $alpha: -15%);
		 }
		 @include breakpoint(large) {
			//  padding: use_gutter(1.5) use_gutter(2);
		 }
		 
		 p { 
			 font-family: $font-body; 
			 font-weight: normal;
			 line-height: 1.25;
			 color: #fff;
 
			 @include breakpoint(medium) {
				 color: inherit;
			 }
			 @include breakpoint(large) {
				 margin-bottom: 1.25rem;
			 }
 
			 a {
				 @include breakpoint(small down) {
					 color: #fff;
					 // @include box-shadow($context: anchor, $color: #fff);
				 }
			 }
		 }
		 
		 // CTA button
		 > a {
			//  @include button;
			@extend .button;
			 @include button-style($background: $accent, $background-hover: auto, $color: #fff);
			 @include button-font;
			 @include breakpoint(small down) {
				 @include button-style($background: transparent, $background-hover: auto, $color: #fff);
				 @include button-hollow-style($color: #fff, $hover-lightness: $button-hollow-hover-lightness);
				 // @include button-style($background: $accent, $background-hover: auto, $color: $accent)
			 } 
		 }
	 } 
 } 
 
 
		 
 // 2. Slider box animation
 // -----------------------
 
 @include breakpoint(medium up) {
	 #slider-main.swiper-container #{$swiperActive} .slider-left .slider-box,
	 #slider_main.swiper-container #{$swiperActive} .slider_left .slider_box {
		 // @include animation(fadeInLeft .75s);
	 }
	 #slider-main.swiper-container #{$swiperActive} .slider-box,
	 #slider_main.swiper-container #{$swiperActive} .slider_box {
		 @include animation(zoomInSmall .75s);
	 }
 }
 
	
 // 3. Controls (Play/pause)
 // -----------------------
 
 #slider-main .swiper-controls,
 #slider_main .swiper-controls {
	 background: $primary-color;
	 padding-left: use_gutter(.5);
	 padding-bottom: use_gutter(.5);
 
	 @include breakpoint(medium up) {
		 position: absolute;
		 bottom: 0;
		 left: 0;
		 padding-left: 1rem;
		 padding-bottom: 10px;
		 z-index: 1;
		 background: transparent;
	 }
	 
	 button {
		 padding: 0;
		 margin: 0;
		 opacity: .75;
		 font-size: 1.25rem;
		 color: #fff;
 
		 @include breakpoint(medium) {
			 font-size: 1rem;
		 }
 
		 &:hover, &:active, &:focus {
			 opacity: 1;
		 }
 
		 &::before { 
			 @include icon;
			 content: get-icon(icon_play); 
		 }
		 &.active::before { content: get-icon(icon_pause); }
 
	 }  
	 
	 span {
		 @include element-invisible;
	 }
 }
 
 
 // 4. Pagination
 // -------------
 
 #slider-main .swiper-pagination,
 #slider_main .swiper-pagination {
	 width: auto;
	 @include breakpoint(small down) {
		 
		 right: use_gutter(0.5);
		 bottom: use_gutter(0.5);
		 left: auto;
	 }
 
	 @include breakpoint(medium) {
		 left: 50%;
		 transform: translateX(-50%);
	 }
	 
	 .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
		 opacity: .5;
	 }
	 .swiper-pagination-bullet {
		 display: inline-block;  
		 margin: 0 5px;
		 border-radius: 12px;
		 background: #fff;
		 text-indent: -9999px;
		 @include transition(all ease .5s);
 
		 &:hover {
			 opacity: 1;
		 }
	 }
 }