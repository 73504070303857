/**
 *	
 *	Related files: 
 *	- components/navigation/nav_main
 *
 *  TABLE OF CONTENTS
 *  
 *  1.	Off-canvas - header
 *	2.	Mobile nav layout
 *	3.	Toggle mobile menu
 *	4.	Page overlay
 *
 *	5.	uoit.ca/academics menu
 *`
 */


// 1. Off-canvas - header
// ----------------------

#nav-mobile .menu-header {
	padding: 1.5rem;
	text-align: center;
	border-bottom: 1px solid $mediumgrey;
	
	a {
		display: block;
	}

	.logo-shield {
		@include shield-stroke($background: $white, $shield: $darkblue, $arc: $lightblue, $arrow: $orange);
	}
}


// 2. Mobile nav layout
// --------------------

#nav-mobile>.is-drilldown {
	// height: 100vh;
}

.is-mobile-menu {

	// height: 100vh; 
	.is-drilldown-submenu {
		display: block;
		background: #fff;
	}

	// Top level links, Back link, Index page link
	>li>a,
	li.js-drilldown-back>a,
	li.is-submenu-parent-item>a {
		@include typography-primary-link($darkblue, #fff);
		text-transform: none;
		font-size: 1rem;
	}

	// All links
	li a {
		border-bottom: 1px solid $mediumgrey;
		padding: 1rem;
		outline: none;
		font-family: $font-heading;
		color: $darkblue;

		&.more {
			color: $accent-color;
			border-left: 1px solid $mediumgrey;
		}

		&[data-login]:after {
			@include icon;
			content: get-icon('icon_lock');
			vertical-align: super;
			font-size: 0.6875rem;
			margin-left: 0.5rem;
			border: 0;
		}

		&:hover,
		&:active,
		&:focus,
		&.more:hover {
			color: #fff;
			background: $darkblue;
		}
	}

	li:not(.is-drilldown-submenu-parent) > a {
		&[data-symlink]:after {
			@include icon;
			content: get-icon('icon_mouse_window');
			vertical-align: super;
			font-size: 0.6875rem;
			margin-left: 0.5rem;
		}
	}
}


// 3. Toggle mobile menu
// ---------------------

.btn_toggleMobileMenu {
	display: block;
	width: 100%;
	padding: .5rem 1rem .5rem 0; //.5rem use_gutter(.5);
	font-family: $font-heading;
	font-size: 1rem;
	font-weight: 900;
	text-align: left;
	text-transform: uppercase;
	color: #fff;

	&:before {
		@include icon;
		content: get-icon('icon_menu');
		width: rem-calc(35);
		// margin-right: rem-calc(5);
		text-align: center;
		@include breakpoint(medium) {
			margin-right: 0;
		}
	}

	&:hover {
		cursor: pointer;
	}

	// [class*="icon_"] {
	// 	margin-right: .5rem;
	// }
}


// 4. Page overlay
// ---------------

.js-off-canvas-exit {
	z-index: 10;
}


// 5. uoit.ca/academics menu
// -------------------------

.is-mobile-menu {
	span.fac {
		@include breakpoint(small down) {
			display: none;
		}
	}

	a {
		color: $primary-color;

		span.fbit,
		span.fed,
		span.feas,
		span.fesns,
		span.fhs,
		span.fsci,
		span.fssh,
		span.grad {
			color: inherit;
		}
	}
}