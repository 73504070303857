/**
 *	
 *	Base variables defined after Foundation
 *	
 *
 *	TABLE OF CONTENTS
 *
 *	1.	Header/footer colours
 *	2.	Sidebar
 *	3.	Breadcrumbs
 *	4.	Module (section) blocks
 *	5.	Tables
 *	6.	Transitions/animations
 *
 */

$link_function_factor: -30%;
$button-function-factor: -15%;

// 1. Header/footer colours
// ------------------------

$site-title-bar: $accent !default;

$header-background: $bluegrey !default;
$header-link-color: $white !default;
$header-link-hover-color: $white !default;

$footer-background: $bluegrey !default;
$footer-top-background: $footer-background !default;
$footer-bottom-background: $footer-background !default;
$footer-link-color: $white !default;
$footer-link-hover-color: $white !default; 

// 2. Sidebar
// ----------

$sidebar_nav_top_level_color: $secondary-color;
$sidebar_nav_secondary_level_color: #757575;
$sidebar_nav_active_color: $primary-color;
$sidebar_border_color: scale-color(get-color(grey), $lightness: 90%);


// 3. Breadcrumbs
// --------------

$breadcrumb_active_color: #ffffff;
$breadcrumb_inactive_color: #b5b5b5;


// 4. Module blocks
// ----------------

$module_bg_light: get-color(greylight); 
$module_bg_medium: get-color(greymedium);
$module_bg_dark: get-color(greydark);


// 5. Tables
// ---------

$table_border_color: get-color(greymedium);


// 6. Transitions/animations
// -------------------------

$transition-duration: .3s;
