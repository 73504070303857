/* 
 * Standard header elements:
 * - header container (sticky)
 * - logo
 * - site tite bar
 *
 * Related files:
 * - components/header/logo
 */

header {
	.sticky-container {
		z-index: 10;
		transition: .3s ease;
	}

	.sticky {
		width: 100%;

		.show-on-sticky {
			display: none;
		}
	}

	// Is sticky
	.sticky.is-stuck {
		box-shadow: inset 0 1px 0 #e2e3df, 0 1px 6px rgba(0, 0, 0, 0.1);

		.show-on-sticky {
			display: block;
		}

		.nav-bar {
			position: relative;
		}

		.logo-bar {
			display: none;
		}

	}
}


// Logo
// ----
.logo-bar {
	background: #fff;
	position: relative;
	z-index: 10;

	> .row {
		// @include breakpoint(xsmall) {
		// 	padding-left: 1.5rem;
		// }
		@include breakpoint(xsmall) {
			padding-left: 1rem;
		}
	}
}

.logo-container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	height: 110px;
	perspective: 200px;

	@include breakpoint(xxsmall only) {
		width: 100%;
		justify-content: center;
	}

	// Signature logo/site title hidden at top
	&.signature-logo {
		height: 80px;
		margin-top: rem-calc(30);

		@include breakpoint(xxsmall only) {
			.logo {
				height: auto;
			}
			.logo .logo-main {
				width: auto;
				height: 65px;
			}
		} 
		@include breakpoint(380px down) {
			.logo .logo-main {
				height: 55px;
			}
		}
	}
	 
	.logo {
		height: rem-calc(80);

		a {
			display: block;
			line-height: 1;
		}

		.logo-main {
			width: auto;
			height: rem-calc(80);
			max-width: none;
			transition: 0.6s ease;
		}

		.logo-text {
			#university > *,
			#ontario > * {
				fill: $darkblue;
			}
			#tech > * {
				fill: $orange;
			}
		}

		.logo-shield {
			@include shield-stroke($background: $white, $shield: $darkblue, $arc: $lightblue, $arrow: $orange);

			.outline-stroke {
				stroke-miterlimit: 7;
				stroke-width: 6.5px;
			}
		}
	
		&:hover {			
			.logo-shield {
				@include shield-stroke-animation;
				#circle-full {
					animation-name: circle-dash-thin;
					stroke-dasharray: 60,80;
					stroke-dashoffset: -80;
				}
			}
		}
		
		// Interim logo - UOIT->OTU animation
		.logo-animation-uoitotu {
			@include breakpoint(xxsmall only) {
		  	height: rem-calc(60); 
				width: auto;
		  }
			@include breakpoint(xsmall) {
				height: rem-calc(80);
				width: auto;		
				max-width: none;		
			}
			transition: 0.6s ease;
		}


		// LEGACY while in transition to full rebrand styles
    img.logo-sticky {
			display: none;
		}
	
		// border settings for second logo
		&.border {	
			border: 0; // override default (global) .border style  
			@include breakpoint(xxsmall only) {
				margin-top: rem-calc(15);
				padding-top: rem-calc(15);
				border-top: 1px solid $uoitdarkblue;
	 		}		 
			@include breakpoint(xsmall) {
				margin-left: rem-calc(35);
				padding-left: rem-calc(35);
				border-left: 1px solid $uoitdarkblue;
			} 
		}
	}

	// 2. Title (legacy)
	// --------
	.title {
		display: flex;		 

		@include breakpoint(xxsmall only) { 
			margin-top: rem-calc(15);
			padding-top: rem-calc(15);
			border-top: 1px solid $uoitdarkblue;
		}

		@include breakpoint(xsmall) {
			min-height: 60px;
			margin-left: rem-calc(35);
			padding-left: rem-calc(35); 
			border-left: 1px solid $uoitdarkblue;
			text-align: left;
		} 

		a { 
			display: flex;
			align-items: flex-end; 
 
			// Arrow  
			@include breakpoint(xsmall) {
				&:after {
					content: '\203A';
					font-size: 2rem;
					color: $accent-color;
					margin-left: 0.4375rem;
					margin-bottom: 0.4375rem; 
					line-height: 1;
					transition: 0.6s ease;
					opacity: 0;
					transform: translateX(-150%);
				}
			}

			// Text
			span {
				transition: 0.3s;
			}
			
			&:hover, &:focus {
				span {
					color: #0077CA;
				}
				@include breakpoint(xsmall) {
					&:after {
						opacity: 1;
						transform: translateX(0);
					}
				}
			}
		}
		
		h1, span {
		font-family: $font-alt-heading;
		font-size: 1rem;
		text-transform: uppercase;
		line-height: 1;
		color: $darkblue;

			@include breakpoint(xsmall) {					
				margin-bottom: rem-calc(14);
				line-height: 1;
			
				&.multiline {
					margin-bottom: 0;
				}
			}			
		}
	}  
	
}


// Site title bar
// --------------
.site-title-bar {
	background: $site-title-bar;
	&.hide-at-top {
		background: $white; 
	}

	@include transition(opacity .3s ease);

	@include breakpoint(medium) {
		.title {
			@include flex-grid-row();
			@include flex-grid-column();
		}
	}

	.title a {
		position: relative;
		display: block;
		padding: .5rem rem-calc(35) .5rem 1rem;
		line-height: 1;
		font-family: $font-heading;
		font-size: rem-calc(19);
		font-weight: bold;
		color: $white;
		transition: none;

		@include breakpoint(small down) {
			padding-left: 1rem + use_gutter(.5);
		}

	}
}

// Visibility - show only on sticky
.sticky.is-stuck .site-title-bar.hide-at-top {
	background: $site-title-bar;
	opacity: 0;
	animation: title-fade-in .5s ease;
	animation-fill-mode: forwards;
}
@keyframes title-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}


// Sticky menu icon links 
// (site home and search)
// ----------------------
.site-title-bar {
	position: relative;

	// Sticky menu icons
	.btn-sticky-shield,
	.btn-sticky-search {
		position: absolute;
		top: 50%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 1;
		transition: transform .3s ease;	
	}
	// add .3s delay to sticky icons animation after opacity transition 
	// when site-title-bar is hidden at the top
	&.hide-at-top {
		.btn-sticky-shield,
		.btn-sticky-search {
			transition: transform .3s ease .3s; 
		}
	}

	// Sticky shield icon
	a.btn-sticky-shield {
		width: rem-calc(30);
		@include breakpoint(xlarge) {
			width: rem-calc(35);
		}

		&::before {
			@include icon;
			content: get-icon(icon_uoitshield);
		}	
	} 
	.btn-sticky-shield {
		color: $white;
		left: 0;
		width: rem-calc(30);
		background: scale-color($site-title-bar, $lightness: -15%);
		justify-content: center;
		transform: translate(-40px, -50%);
	}

	// Sticky search icon
	button.btn-sticky-search {
		color: $white;
		&::before {
			@include icon;
			content: get-icon(icon_search);
		}
	}
	.btn-sticky-search {
		right: 0;
		width: rem-calc(35);
		background: scale-color($site-title-bar, $lightness: -15%);
		justify-content: center;
		transform: translate(50px, -50%);
	}

	// Legacy layout 
	.btn-sticky-shield,
	.btn-sticky-search {
		a,
		button {
			display: block;
			text-align: center;
			width: 100%;
			color: $white;
			&::before {
				@include icon;
			}
		}	
	}
	.btn-sticky-shield a {
		&::before {
			content: get-icon(icon_uoitshield);
		}
	}
	.btn-sticky-search button {
		&::before {
			content: get-icon(icon_search);
		}
		&:hover {
			cursor: pointer;
		}
	}
	
}

.sticky.is-stuck {
	.site-title-bar {
		.btn-sticky-shield {
			transform: translate(0, -50%);
		}
		.btn-sticky-search {
			transform: translate(0, -50%);
		}
	}
}
.sticky.is-anchored .site-title-bar.hide-at-top {
	.btn-sticky-shield, 
	.btn-sticky-search {
		transition: none;
	}
}