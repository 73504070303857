/**
 * Adds a linear gradient overlay to an element
 * e.g. text on top of image with black/transparent gradient
 */
@mixin gradient-overlay($relative-parent: true, $position: top, $colors: (transparent, rgba(0,0,0,0.1), rgba(0,0,0,.8))) {
	@if $relative-parent == true {
		position: relative;
	}

	&::after {
		position: absolute;
		display: block;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		background: linear-gradient(to $position, $colors);
	}
}
