.home-ctas { 
  @include flex-grid-row();
  @include breakpoint(small up) {
    @include row_gutter();
  } 

  // heading  
  h2 {
    @include breakpoint(xxsmall up) {
      margin-top: 0;
      margin-bottom: use_gutter();
    }
    @include breakpoint(small up) {
      margin-bottom: 0;
    }
  } 
  
  .btn_cta, // deprecated 
  .btn_home_cta, // deprecated
  .button {     
    @include button-icon-right($icon-size: 40px, $left-button-padding: 15px, $right-button-padding: 15px); 
    
    // override default colours for yellow
    @each $name, $color in $button-palette {
      @if $name == yellow {
        &.#{$name} { 
          background-color: $yellowdarker !important;
          color: color-pick-contrast($yellowdarker, ($button-color, $button-color-alt)) !important;

          &:hover, &:focus {  
            background-color: scale-color($yellowdarker, $lightness: $button-background-hover-lightness) !important;
          }
        }
      }
    }

    display: flex !important;
    align-items: center;
    z-index: 1;
    margin-bottom: 0;
    font-size: rem-calc(22);  
    text-align: left;

    @include breakpoint(small down) {
      font-size: rem-calc(20); 
      @include button-icon-right($icon-size: 30px, $left-button-padding: 15px, $right-button-padding: 15px);
    }  
  }

  @include breakpoint(xsmall down) {
    .column {
      @include flex-grid-column(12);
    }
    br {
      display: none;
    }
    span {
      display: inline !important;
    }
  }

  @include breakpoint(small) {
    .btn_cta, // deprecated 
    .btn_home_cta, // deprecated 
    .button {
      height: rem-calc(70); 

      // deprecated
      span span {
        display: block;       
      }
    }
  }
} // .home_ctas

 


// - - - - - - - - - - - - - - - - - - - - - - - - - 
//
// DEPRECATED: Individual button styles
//
// - - - - - - - - - - - - - - - - - - - - - - - - -


// Home page
// - - - - - - - - - - - - - - - - - - - - - - - - -

// UOIT 
#btn_cta_explore {
  background: $uoitpink;
  color: #fff;
  &::before { content: get-icon(icon_exploreuoit); }
  &:hover, &:active, &:focus {
    background-color: scale-color($uoitpink, $lightness: -20%);
  }
}
#btn_cta_programs {
  background: $uoitgreen;
  color: #fff;
  &::before { content: get-icon(icon_viewprograms); }
  &:hover, &:active, &:focus {
    background-color: scale-color($uoitgreen, $lightness: -20%);
  }
}
#btn_cta_viewbooks {
  background: $uoitorange;
  color: #fff;
  &::before { content: get-icon(icon_downloadviewbooks); }
  &:hover, &:active, &:focus {
    background-color: scale-color($uoitorange, $lightness: -20%);
  }
}
#btn_cta_tour {
  background: $uoitlightblue;
  color: #fff;
  &::before { content: get-icon(icon_launchtour); }
  &:hover, &:active, &:focus {
    background-color: scale-color($uoitlightblue, $lightness: -20%);
  }
}

// RO
#btn_home_cta_ro_askAQuestionHS {
  background: $uoitpink;
  color: #fff;
  &::before { content: get-icon(icon_askaquestion); }
  &:hover, &:active, &:focus {
    background-color: scale-color($uoitpink, $lightness: -20%);
  }
}
#btn_home_cta_ro_chat {
  background: $uoitgreen;
  color: #fff;
  &::before { content: get-icon(icon_chat); }
  &:hover, &:active, &:focus {
    background-color: scale-color($uoitgreen, $lightness: -20%);
  }
}
 