/**
 *	
 *	Related files: 
 *	- components/multimedia/photogallery
 *
 */
 

// Individual image (Light gallery - pop up)
// ----------------
.img-enlarge,
.img-enlarge:hover {
	cursor: pointer; 
}


// Image with caption
// ------------------
figure {
	margin: 0;
}

figcaption {
	margin-top: .25rem;
}
.landing-page-content figcaption,
.page-content figcaption,
.page-sidebar figcaption {
	font-family: $font-heading;
}

img.caption {
	@extend figure;
}


// Thumbnail
// ---------
.th {
	@extend .thumbnail;
}

// Circle crop
// -----------
img.circle {
	border-radius: 50%;
}