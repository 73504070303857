label {
	input,
	textarea {
		font-weight: normal;
	} 
}

.sublabels label,
label.sublabels,
fieldset > label {
	font-size: rem-calc(15);
	font-weight: normal;
}

legend.legend-label {
	@include form-label;
}

// input/label siblings
// [type='checkbox'] + label,
// [type='radio'] + label { 
// }
// input/multi-line label siblings
[type='checkbox'] + label.top, 
[type='radio'] + label.top {
	vertical-align: top; 
	width: 90%;
	margin-right: 0; 
}

// form error message
.form-error {
	margin-top: -$form-spacing;
	padding: ($form-spacing * 0.25) ($form-spacing * 0.5);
	background: $input-error-color;
	color: #fff;
}
 
// required asterisk
span.required {
	color: $input-error-color;
}

// fieldset
form .fieldset {
	> legend:not(.legend-label) {
		font-family: $font-heading;
		font-weight: bold;
		font-size: rem-calc(20);
		color: $uoitdarkblue;
		text-transform: uppercase; 
	}
}




// 1. Payment forms
// - - - - - - - - - - - - - - - - - - - - - - - - -

form.payment-form {
	.column:not([class*='xxsmall-']),
	.columns:not([class*='xxsmall-']) {
		@include breakpoint(xsmall down) {
			@include flex-grid-column(12);
		}
	}

	table.tblConfirmation {
		th {
			text-align: left;
		}
	}

	.form-submit .button {
		@include breakpoint(xxsmall only) {
			width: 100%;
		}
	}
}

 
@mixin fieldset-card() {

}

fieldset.card {
	position: relative;
	background: scale-color(grey, $lightness: 95%);
	border: 1px solid get-color(mediumgrey);
	// box-shadow: 0 0 1px 1px get-color(lightgrey), 1px 1px 3px get-color(mediumgrey); 
	margin-bottom: 1rem;
	padding: ($form-spacing*4) $form-spacing $form-spacing;

	> legend { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;  
		margin: 0; 
		padding: $form-spacing;  
		border-bottom: 1px solid get-color(lightgrey); 
		background: #fff;
		font-weight: 900;
		text-transform: uppercase; 
		line-height: 1;
		// color: $darkblue;
	}
}
