blockquote {
	margin: 0 1rem 1rem;
	font-family: $font-heading;
	font-size: rem-calc(18);
	font-style: italic;
	color: $darkblue;
	
	cite {
		display: block;
		margin-top: 1rem;
		font-weight: bold;
		font-style: normal;
		color: $darkblue;
	}

	.quote {
		&-name {
			display: inline-block;
		}
		&-title {
			display: block;
			font-weight: normal;
			font-style: normal;
		}
		&-credential {
			display: block;
			font-weight: normal;
			font-style: italic
		}
	}

  &.border { 
		border: 0;
		position: relative;

		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 3px;
			height: 100%;
			background-color: $orange;
			content: '';
		}
	}

  &.quotes {
		position: relative;
		display: table;
		margin-left: 1.5rem;
		margin-right: 1.5rem;

		&::before,
		&::after {
			position: absolute;
			bottom: 100%;
			height: 1rem;
			font-family: $font-body;
			font-size: 4rem;
			font-style: normal;
			line-height: 1;
			color: $orange;
		}
    &::before {
      content: '\201c';
			left: -1.5rem;
    }
    &::after {
      content: '\201d';
			right: -1rem;
		}
  }
}

[class*="callout-quote"] {
  font-weight: bold;
	font-size: 1.25rem;
	font-style: normal;
  position: relative;
  padding-top: 2rem;
  margin-top: 2rem;
	transform-origin: 50% 100%;

	@include breakpoint(medium) {
		font-size: 2rem;
	}
	
	&.light {
		font-weight: normal;
	}

	.quote {
		&-name, &-title, &-credential {
			font-weight: normal;
			font-style: normal;
			transform-origin: 50% 100%;
		}

		&-name {
			text-transform: uppercase;
	 
			&:not(:last-of-type) {
				margin-bottom: .5rem;
			}
		}

		&-title {
			margin-top: 0.25rem;
			font-style: italic;
		}
	}
	
  &::before, &::after {
    @include brand-chevron;
    position: absolute;
    bottom: 100%;
    transform: rotate(-90deg);
	}
  &::after {
    transform: translateX(calc(100% + 0.5rem)) rotate(-90deg);
  }
}
.callout-quote.small,
.callout-quote-small {
	@include breakpoint(medium) {
		font-size: 1.5rem;
	}

	padding-top: 1rem;
}
.callout-quote.xsmall,
.callout-quote-xsmall {
	@include breakpoint(medium) {
		font-size: 1.25rem;
	}

	padding-top: 1rem;

	&::before, &::after {
		@include brand-chevron($size: 1.5rem, $thickness: 0.75rem);
	}
}


blockquote.animate {
	animation: 1s 0.5s fade-in backwards;

	&.border::before {
		animation: .75s 1s slide-in-up backwards;
	}

	&.quotes::before, &.quotes::after {
		animation: .75s 1s slide-in-up backwards;
	}

	&.callout-quote::before, &.callout-quote::after {
		animation: 1s 1s slide-up-y backwards;
	}

	cite {
		animation: 1s 2s fade-in backwards;
	}

	.quote-credential {
		animation: 1s 2.5s fade-in backwards;
	}

	.quote-title {
		animation: 1s 3s fade-in backwards;
	}
}