// v2 profile pages 


/* - - - - - - - - - - - - - - - - - - - - - - - - -
 *
 *  TABLE OF CONTENTS
 *  
 * - PROFILES INDEX
 *
 * - PROFILE PAGE
 *   1. Header
 *    	 a. Photo
 *   		 b. General info
 *   		 c. Contact
 *   2.	Research keywords
 *   3.	Videos/photos
 *   4.	Biography
 *   5.	Education
 *   6.	Media appaearances
 *   7.	Common elements
 *
 */


/**
 * PROFILES INDEX
 */

 .page_profile_index {
	img {
		@extend .float_left;
		@include breakpoint(xsmall) {
			//@extend .float_left;
			max-width: 100px;
			margin-right: use_gutter();
		}
	}
}

.profileIndex {
	@extend %clear;

	.profileIndex_content {
		@include breakpoint(xsmall) {
			display: table;
		}
	}

	.profileIndex_heading {
		margin-bottom: 0;
		text-transform: none;
	}

	.profileIndex_general {
		margin-bottom: .5rem;

		.profileIndex_position {
			margin-bottom: 0;
			font-family: $font-heading;
			font-weight: bold;
			font-size: 1.1em;
			color: $uoitdarkblue;	
		}

		.profileIndex_department {
			@extend .profileIndex_position;
			font-family: $font-heading;
			color: $uoitdarkblue;
		}	

		.profileIndex_summary {
			margin-bottom: 0;
		}
	}

	.profileIndex_keywords {
		margin-bottom: .5rem;

		> p {
			margin-bottom: .15rem;
			color: $uoitdarkblue;
		}

		ul {
			margin-left: 0;
			margin-bottom: 0;
		}
		li {
			@extend .label;
			background-color: $uoitgreymedium;
			list-style: none;
			font-size: 1em;
			color: $body-font-color;
			white-space: normal;
		}	
	}

	.profileIndex_contact {
		@include clear;

		p {
			margin-bottom: 0;
		}
	}

}


/**
 * PROFILE PAGE
 */

.profile_page .profile_row {
	@include flex-grid-row(); 
	margin-bottom: 1.25rem;
}

// 1. Header
// ---------

.profile_header {
	@include flex-grid-row();
	@include flex-align($y: top);

	.profile_info {
		@include flex-grid-column();
	}
}

// 1.a Photo
// ---------	

.profile_photo {
	@include flex-grid-column(3);
	max-width: 200px;
}	

// 1.b. General info
// -----------------

.profile_general {
	@include flex-grid-column();	

	.profile_heading {
		text-transform: none;
		span {
			@include serif;
		}
	}

	.profile_position {
		@include serif;
		margin-bottom: 0;
		font-weight: bold;
		font-size: 1.1em;
		color: $darkblue;
	} 
	.profile_department,
	.profile_faculty {
		@include serif(italic);
		color: $darkblue;
	}

	.profile_summary {
		margin-top: 1.25rem;
	}
}

// 1.c. Contact info
// -----------------

.profile_contact {	
	background: $lightgrey;
	padding: .5rem;

	@include flex-grid-column(12); 

	@include breakpoint(medium) {
		@include flex-grid-column(3);
	}

	h2 {
		font-size: 1.5em;
	}

	p {
		margin-bottom: .5rem;
		font-size: .9em;
		line-height: 1.3;
	}
	p:last-child {
		margin-bottom: 0;
	}
}


// 2. Research keywords
// --------------------

.profile_keywords {
	@include flex-grid-column(12); 
 	
 	// when more than 1 section is present, make sections smaller
	@include breakpoint(small) {
		&:nth-last-child(2):first-child {
	    @include flex-grid-column(6);
	    + .profile_keywords {
	      @include flex-grid-column(6);
	    }
	  }
	}

	ul {
		margin-left: 0;
	}
	li {
		@extend .label;
		background-color: $lightgrey;
		margin-bottom: .25rem;
		list-style: none;
		font-size: 1em;
		color: $body-font-color;
		text-align: left;
		white-space: normal;
	}
}


// 3. Videos/photos
// ----------------

.profile_videos {
	position: relative;
	background: $lightgrey;
	padding: use_gutter(1.5) use_gutter() use_gutter();

	figure {
		@include flex-grid-row();
		.profile_video {
			@include flex-grid-column();   
			@include breakpoint(xxsmall only) {
				padding-left: 1px;
				padding-right: 1px;
			}
		}		
	}
	figcaption {	
		font-family: $font-body;
		font-weight: normal;		
		h3, h4 {
			@include typography-primary-link($darkblue, $darkblue, 1.2rem);
		}
		@include flex-grid-column(12);
		@include breakpoint(small) {
			@include flex-grid-column(4);
		}	
		@include breakpoint(xxsmall only) {
			padding: 0;
		}		
	}
	
	.swiper-pagination {
		position: static;
	}
	.swiper-pagination-bullet {
		margin: 0 5px;
	}
}

.profile_photos { 
	position: relative;
	// see multimedia/photogallery.scss for swiper layout
}


// 4. Biography
// ------------

.profile_biography {
	@include flex-grid-column();
}


// 5. Education
// ------------

.profile_education {
	// @include flex-grid-column(12);
	@extend .float-right;

	.profile_educationList {
		margin-left: 0;

		li {
			padding: use_gutter()/2 0;
			border-top: 1px solid $lightgrey;
			// font-family: $font-heading;
			list-style: none;
			color: $darkblue;
			&:last-child {
				border-bottom: 1px solid $lightgrey;
			}
		}
		.profile_education_degree {
			@include serif;
			display: block;
			font-weight: bold;
			@include breakpoint(small) {
				font-size: 1.4em;
			}
		}
		.profile_education_school {
			@include serif(italic);
		}
		.profile_education_year {
			@include serif;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
}
// when bio section is present, make education smaller
.profile_biography:nth-last-child(2):first-child + .profile_education {
	@include breakpoint(small) {
		@include flex-grid-column(4);
	}	
}


// 6. Media appearances
// --------------------

.profile_involvement {
	article {
		padding: use_gutter();
		border-bottom: 1px solid $lightgrey;

		&:last-child {
			border-bottom: 0;
		} 

		img {
			@extend .float-left;
			max-width: 250px;
		} 

		> div {
			display: table;
		} 
	} 
}


// 7. Common elements
// ------------------

.profile_videos,
.profile_photos,
.profile_courses,
.profile_research,
.profile_publications,
.profile_involvement,
.profile_customSection {
	@include grid-column(12);
	margin-bottom: 1.25rem;
}


/**
 * FEATURED PROFILE EMBED
 * (2019-06-17; these styles originally from profile-expert.scss temporarily until full rebrand)
 */

.featured-expert {
	position: relative;
	font-family: $font-heading;

	.content {
		padding: rem-calc(30);
		max-height: none; // home page spotlight override
	}
	&.spotlight:not([data-width]) .content {
		@extend .feature-module;
		@extend .clear;
	}

	.feature-subheading {
		padding-bottom: 1rem;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: $darkblue;
		font-size: 1rem;
		text-transform: uppercase;
	}

	.image {
		@include breakpoint(xxsmall only) {
			max-width: 100%;
			margin-bottom: 1rem;
			margin-right: 0;
			text-align: center;
		}
		@include breakpoint(xsmall) {
			max-width: 25%;
			margin-right: 30px;
			margin-bottom: 20px;
			float: left;
		}
		@include breakpoint(medium) {
			img {
				width: 200px;
			}
		}
	}

	.name {
		display: table;
		margin-bottom: 0;
		font-size: 1.75rem;
		font-weight: 900;
		color: $darkblue;
		a {
			color: $darkblue;
			&:hover,
			&:focus,
			&:active {
				color: $lightblue;
			}
		}
	}

	.position {
		display: table;
		font-size: 1.4rem;
		color: $darkblue;

		&::after {
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			margin-top: 1rem;
			background: $orange;
		}
	}


	.body {
		display: table;
		
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.is-expert-in {
		margin-bottom: 0;
	}

	.keywords {
		list-style: none;
		margin-left: 0;
		color: $darkblue;

		li {
			display: inline-block;

			&::after {
				content: ', ';
			}
			&:last-child::after {
				content: '';
			}
		}
	}

	// Specified width
	&[data-width] {
		background: $lightgrey;

		.body {
			clear: left;
		}

		@include breakpoint(small down) {
			width: 100% !important;

			&[class*=float] {
				margin-bottom: 1rem;
			}
		}

		@include breakpoint(medium) {
			.name {
				font-size: 1.5rem;
			}
			.position {
				font-size: 1.25rem;
			}

			&.float-left, &.float_left {
				margin-right: rem-calc(30);
				margin-bottom: rem-calc(15);
			}
			&.float-right, &.float_right {
				margin-left: rem-calc(30);
				margin-bottom: rem-calc(15);
			}
		}
	}

	// Brand arrow {
	&[data-display*=brandarrow] {
		&::after {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: 30px;
			height: 30px;
			border-style: solid;
			border-color: $orange;
			border-width: 15px 15px 0 0;
			content: ' ';
		}
	}
}

// Profile displayed as a feature-image spotlight module
.featured-expert .feature-image,
.feature-image .featured-expert {
	display: block;

	p.name {
		margin-bottom: .2rem;
		line-height: 1;
	}

	p.position {
		font-weight: bold;
		line-height: 1.2;
		&::after {
			display: none;
		}
	}

	.body {
		margin-top: 1rem;
	}

	.feature-link {
		@include feature-text-link-style($white, $darkblue);
	}
}
// feature-image inside a CMS spotlight
.feature-module .featured-expert .content {
	padding: 0;
}