.social_feeds {
  @include row_gutter();   

  @include breakpoint(xsmall down) {   
    @include flex-grid-column(12);
  }
  
  // Tabs
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  .tabs.vertical {
    @include grid-column($columns: 2, $gutters: 0); 
    
    margin: 0; 
    padding: 0;
    border: 0;
    
    .tabs-title { 
      margin: 0 0 use_gutter()/2;// !important;  
      border: 0;
      
      a {
        padding: 1rem 0;
        background: $module_bg_medium;
        text-align: center;
      }
      &.is-active a,
      a:hover, a:active {
        background: $module_bg_light;
      }

      a img {
        max-width: 30px;
      }
    }
  }
  

  // Content
  // - - - - - - - - - - - - - - - - - - - - - - - - -

  .tabs-content {
    @include grid-column($columns: 10, $gutters: 0);  
    margin-bottom: 0;
    height: 100%;
    border: 0;
  
    .tabs-panel {
      padding: 0;
      height: 100%;

      .home-module {
        height: 100%;
      }
    
      .social_content_wrapper {
        background: #fff;
        width: 100%;
        // height: 100%;
        // max-height: $max_module_height;
              
      }
    } 
  }

} // .social_feeds

// Twitter 
.social_feeds .social_feed_twitter {
} // #social_feed_twitter

// Facebook 
.social_feeds .social_feed_facebook {
  .social_content_wrapper {
    overflow-x: auto;
  }
}

// Youtube 
.social_feeds .social_feed_youtube {
  .social_content_wrapper {
    background: transparent;
  }

  figure {
 
  }  
  
  h4 {
    margin-bottom: 0;
    font-family: $font-body;
    font-weight: 900;
    font-size: .88rem; 
    text-transform: uppercase;
    color: $uoitdarkblue;
  }  

  p {
    margin: 0;
    font-size: .94rem;
    line-height: normal;
  } 
} // #social_feed_youtube