.blog {
	@include row_gutter();
	
	ul {
		@include breakpoint(medium) {
      overflow-y: scroll;
      overflow-x: hidden;
    }
    
    width: 100%; 
    max-height: $max_module_height; // max height set so giant list of items doesn't show before being dynamically resized in JS
    margin: 0;
    list-style: none;
	}
	
	a {
		display: block;
		clear: both;
	}

  img {
		width: 100px;
		float: left;
		margin-right: .5rem;
		margin-bottom: 1rem;
  }

  .info {
  	display: table;
		
		span {
			display: block;
		}

  	.date {
			margin-bottom: 0;
      font-family: $font-heading;
      font-size: .88rem; 
      text-transform: uppercase;
      color: $darkblue; 
  	}
  }
}