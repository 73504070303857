.page_sidebar_widget {
	padding: use_gutter(1);
	background: $module_bg_light;

	h2 {
		@extend h3;
	}
}

.widget_contact {
	margin-bottom: 1rem;
	
	p {
		margin-bottom: 0;
	}

	ul {
		list-style: none;
		margin-left: 0;

		li {
			margin-bottom: .375rem
		}

		strong {
			// font-family: $font_body_bold;
		}
	}

	.icon {
		margin-right: .25rem;
		color: $uoitgrey;
	}
}