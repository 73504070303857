$font-file-path: 'https://shared.ontariotechu.ca/global/files/fonts/' !default;
@debug 'Font file path: ' + $font-file-path;

@import "../components/typography/fonts";

// UOIT
$ubuntu: "Ubuntu",  Arial, Helvetica, sans-serif;
$franklinGothic: "Franklin Gothic", Arial, Helvetica, sans-serif;
$franklinGothicCondensed: "Franklin Gothic", Arial, Helvetica, sans-serif; //"Franklin Gothic Condensed"
$serif: "Lora", serif;
$uoit_icon_font: "UOIT Icons";
$uoit-icon-font: "UOIT Icons";

// UOIT/DC
$uoitdcfont: 'Roboto', Arial, Helvetica, sans-serif;
$uoitdcfont_secondary: 'Roboto Condensed', Arial, Helvetica, sans-serif;
