.page-sidebar {
	@include grid-column(12);//, use_gutter(.5));	

	@include breakpoint(small) {
		@include grid-column(6);//, use_gutter(1));
		@include grid-column-position(-6);		
	}

	@include breakpoint(medium) {
		@include grid-column(3);//, use_gutter());
		@include grid-column-position(-9);
	}

	margin-top: use_gutter();
	margin-bottom: use_gutter();

	@media print {
		display: none;
	}
}
