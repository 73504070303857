/* - - - - - - - - - - - - - - - - - - - - - - - - -

	Related files: 
	- layout/body/page_sidebar

  TABLE OF CONTENTS
	
	1.	Nav settings

	2.	uoit.ca/academics menu

- - - - - - - - - - - - - - - - - - - - - - - - - */


// 1. Nav settings
// - - - - - - - - - - - - - - - - - - - - - - - - -

.page-sidebar nav {
	margin-bottom: 1rem;
	
	// hide menu until drilldown is initialized
	.is-sidebar-menu {
		opacity: 0; 
	} 

	.is-drilldown-submenu {
		&.no-transition {
			@include transition(none);
		}
		&.is-active {
			box-shadow: 0 40px 0 #fff; // workaround for height calculation errors
		}
	}

	ul {	
		display: block;
		margin-left: 0;
	}
	
	:not(.is-drilldown-submenu-parent) > a {
		&[data-symlink]:after {
			@include icon;
			content: get-icon('icon_mouse_window');
			vertical-align: super;
			font-size: 0.6875rem;
			margin-left: 0.5rem;
		}
	}

	a {
		outline: none;
		color: map-get($foundation-palette, grey);

		&[data-login]:after {
			@include icon;
			content: get-icon('icon_lock');
			vertical-align: super;
			font-size: 0.6875rem;
			margin-left: 0.5rem;
			border: 0;
		}
	}

	// active link; separate page link and submenu-arrow link
	li:hover {
		background: map-get($foundation-palette, greymedium);
	}
	
	li > a:first-child {
		&:hover, &:active, &:focus {
			background: map-get($foundation-palette, primary); //$menu-item-background-active;
			color: $white;
		}
	}
	li > a.more {
		&:hover, &:active, &:focus {
			background: map-get($foundation-palette, primary); //$menu-item-background-active;
			color: $white;
		}
	}
} 



// 2. uoit.ca/academics menu
// - - - - - - - - - - - - - - - - - - - - - - - - -

.is-sidebar-menu {
	span.fac {
		display: none;
	}
	a {
		color: $menu-item-color-active;
		span.fbit, 
		span.fed,
		span.feas,
		span.fesns,
		span.fhs,
		span.fsci,
		span.fssh,
		span.grad {
			color: inherit; //get-color(grey);
		}
	}

	
}