/**
 *  
 *  TABLE OF CONTENTS
 *  
 *  1.  General settings
 *  2.  Module heading
 *  3.  Module content
 *  
 *  4.  Quick links
 *`
 */

// 1. General settings
// -------------------

$max_module_height: 306px !default;
$max_newsevents_height: 306px !default; // to account for taller $max_module_height on custom css builds (e.g. uoit.ca)

.row .row.home-ctas,
.row .row.home-modules {
  margin-left: 0;
}
@include breakpoint(1280 down) {
  .row .home-ctas,
  .row .home-modules {
    margin-left: auto;
    margin-right: auto;
  }
}

.home-module {
  background: $module_bg_light;
  padding: rem-calc(20) rem-calc(30) rem-calc(30);
  
  @include breakpoint(small up) {
    height: 100%;
  }
  
  @include breakpoint(medium up) {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch; 
  }
  

  // 2. Module heading
  // -----------------
  
  .heading {  
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;      
    margin-bottom: .5rem;

    h2, h3 {
      margin-bottom: 0;
      font-family: $font-heading; 
      font-size: rem-calc(30);
      line-height: 1;
      text-transform: uppercase;
      color: $accent-color;    
      @include breakpoint(xxsmall only) {
        font-size: rem-calc(28); 
      }
      @include breakpoint(medium) {
        font-size: rem-calc(32); 
      } 
    }
  }

  .see_all {
    @include typography-primary-link($accent-color, $accent-color, 1.2rem);
    
    span {
      content: '';
      display: inline-block;
      margin-left: 5px;
      border-left: .65rem solid $accent-color;
      border-top: .35rem solid transparent;
      border-bottom: .35rem solid transparent;
      border-right: 0;     
    }
    
    &:hover, &:active, &:focus {
      span {
        border-left-color: scale-color($accent-color, $lightness: -20%);
      }
    }
  }
  

  // 3. Module content
  // -----------------
  
  .content {
    flex: 1 0 auto;
    position: relative;
    width: 100%;
    // height: 100%; 
    @include breakpoint(medium up) {
      max-height: $max_module_height;
      overflow-y: auto;
      overflow-x: hidden;

      &.no-max-height {
        max-height: none;
      }
    }

    &.bg-white {
      background: #fff;
    }

    figure {
      margin: 0;
    }

    h2 {
      font-size: rem-calc(26);
    }
  }
}


// 4. Quick links
// --------------

.module_home_quickLinks,
.module-quick-links {
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
  
  li {
    margin-bottom: .25rem;
  }
  a {
    @extend .button;
    display: block;
    margin-bottom: 0;
    border-left: 5px solid scale-color($button-background, $lightness: -20%);
    text-align: left;
  }
  
  // Social links 
  .social-quick-links {
    margin-top: -.25rem;      
    a.button {
      margin-top: .25rem;
      // border-left: 0;
    }
  }
}

