// 3.b. Sidebar
// - - - - - - - - - - - - - - - - - - - - - - - - -

$sidebar_cta_bg_color: $uoitdarkblue;

// button in sidebar options
.page-sidebar .sidebar-ctas {
  padding: 0 1rem; 
}
// button in a block, in a system region
.page-sidebar > .btn_cta, // deprecated,
.btn_sidebar_cta, // deprecated 
.page-sidebar > .button {
  margin-left: 1rem;
  margin-right: 1rem;
}

.page-sidebar .btn_cta, // deprecated,
.page-sidebar .btn_sidebar_cta // deprecated 
{
  @extend .button;
}
.page-sidebar > .button, // button in a block, in a system region
.page-sidebar .sidebar-ctas > .button, // button in sidebar options 
.page-sidebar .btn_cta, // deprecated,
.page-sidebar .btn_sidebar_cta // deprecated
{  
  display: block;
  border: 0;
  margin-bottom: rem-calc(10);
  padding-right: 3.75rem !important;
  text-align: left;
  
  @include button-icon-right($icon-size: 1.675em, $right-button-padding: 3.5rem);
  
  // override default colours for yellow
  @each $name, $color in $button-palette {
    @if $name == yellow {
      &.#{$name} { 
        background-color: $yellowdarker !important;
        color: color-pick-contrast($yellowdarker, ($button-color, $button-color-alt)) !important;

        &:hover, &:focus {
          background-color: scale-color($yellowdarker, $lightness: $button-background-hover-lightness) !important;
        }
      }
    }
  }

  // if setting arrow size in js, can't change pseudo element via js; must use .arrow element
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-left: 20px solid transparent; // size dynamically set in ctas.js (based on element height)
    border-top: 20px solid #fff; // size dynamically set in ctas.js
    border-bottom: 20px solid #fff; // size dynamically set in ctas.js
    border-right: 0;     
  }

  // arrow
  .btn_sidebar_cta_arrow, //deprecated
  .arrow  {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-left: 20px solid transparent; // size dynamically set in ctas.js (based on element height)
    border-top: 20px solid #fff; // size dynamically set in ctas.js
    border-bottom: 20px solid #fff; // size dynamically set in ctas.js
    border-right: 0; 
    @include transition(all .3s ease); 
  }

}