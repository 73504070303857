/**
 *	
 *	Related files: 
 *	- - components/containers/feature-module
 *
 *  TABLE OF CONTENTS
 *  
 *	1.	General settings
 *	2.	Home page program finder
 *			a.	Heading/icon
 *			b.	Content
 *			c.	Form fields
 *	3.	Sidebar program finder
 *`
 */


// 1. General settings
// -------------------

.program_finder,
.program-finder {

	program-list,
	#findProgramFilter {

		// @include flex-grid-row;
		@include breakpoint(small) {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;

			select {
				margin: 0 use_gutter(.5) 0 0;
			}

			.button,
			button {
				border: none;
				margin: 0;
			}
		}
	}


	select,
	.button {
		margin-bottom: 1rem;
		// @include flex-grid-column(12);

		@include breakpoint(small) {
			margin-bottom: 0;
			// @include flex-grid-column(4);
		}
	}

	// @include breakpoint(large) {
	// 	.button {
	// 		min-width: 100px;
	// 	}
	// }

	// @include breakpoint(xsmall down) {
	// 	select {
	// 		margin-bottom: 1rem;
	// 	}

	// 	.column,
	// 	.columns {
	// 		@include flex-grid-size(12);
	// 	}
	// }

	// @include row_gutter();


	// .feature-module {
	// 	padding: use_gutter(1) use_gutter(1.5);
	// }
}


// 2. Feature program finder (home page)
// -------------------------

.program-finder .feature-module,
.program_finder .feature-module {
	#findProgramFilter {
		@include breakpoint(small) {		
			@include flex-grid-row($wrap: false);
			justify-content: center;
			padding: 1rem;
		
			select {
				margin: 1rem;
			}
		}
		border-top: 1px solid $lightgrey;
		border-bottom: 1px solid $lightgrey;
	}
	
	button {
		margin-top: 2rem;
	}	
}


// 3. Sidebar program finder
// -------------------------

.page-sidebar .program_finder,
.page-sidebar .program-finder {
	padding: use_gutter();
	margin-bottom: 1rem;
	background: $module_bg_light;

	program-list,
	#findProgramFilter {
		display: block;

		select {
			margin: 0 0 1rem;
		}
	}

	

	.button {
		@extend .expanded;
		background-color: $lightblue;

		&:disabled {
			background-color: $lightblue;
		}
	}
}