.featured-expert {
	// background-color: #fff;
	// padding: .5rem;

	.expert {
		display: flex;
	}
	.col-xs-2 {
		width: 30%;
		img {
			margin-bottom: 1rem;
		}
	}
	.col-xs-10 {
		width: 70%;
		margin-left: use_gutter();
	}

	.topic-wrap {
		// display: flex;
		// flex-wrap: wrap;
		margin-bottom: 0;

		.topic-tag {
			@extend .label; 
			@extend .tertiary;
			margin: 0 1px 3px; 
			// text-align: center; 
			// flex: 1 1 0px; 
			font-style: normal;
			white-space: normal;
			background: transparent;
			border: 1px solid $uoitgrey !important;
			color: $uoitgrey;

			@include breakpoint(medium) {
				&:nth-child(n+8) {
					display: none;
				}
			}
			
		}
	}

}
