/**
 *	Related files: 
 *  - components/header/search (search styles)
 *	- containers/google-search (google search fields)
 *	- components/footer/footer (link styles)
 */

.top-nav-links {
	position: relative;
	max-height: 0px;
	background: $header-background;
	overflow: hidden;
	@include transition(max-height .75s ease);

	&.open {
		max-height: 950px;

		@include breakpoint(small) {
			max-height: 500px;
		}
	}
}

.top_nav {
	padding-top: rem-calc(20);
	padding-bottom: rem-calc(20);
}

.emergency-info+.top_nav {
	padding-top: rem-calc(25);
}

// See also: components/footer/footer (link styles)
.top_links {
	font-family: $font-heading;
	
	p.header, li a {
		color: $header-link-color;
	}
	li a {
		&:hover, &:active, &:focus {
			border-bottom: 1px dotted $header-link-hover-color; 
		}
	}
	
	@include breakpoint(small) {
		.column {
			max-width: 300px;
		}
	}
}


// Quick links fixed bar
// ---------------------

$quick-links-button-height: rem-calc(35);

.quick-links-fixed-bar,
.search-bar {
	.button {
		height: $quick-links-button-height;
		line-height: $quick-links-button-height;
		padding: 0 1rem;
		margin-bottom: 0;
		border: 1px solid $white;
		@include button-style($white, rgba($white, .5), $bluegrey);

		@include breakpoint(xxsmall only) {
			padding: 0 .75rem;
			margin-bottom: 5px;
		}

		&:hover,
		&:active,
		&:focus {
			color: $bluegrey;
		}
	}
}



// eSSENTIAL accessibility link
// ----------------------------

#essential-accessibility,
.essential-accessibility {
	margin-left: 1rem;
	border-bottom: none !important;

	img {
		height: 20px;
		width: auto;
		transition: transform $transition-duration ease-out;
	}

	&:hover,
	&:active,
	&:focus {

		img {
			transform: scale(1.05);
		}
	}
}


// Emergency info button
// ---------------------

.emergency-info {
	@include grid-row();

	a.button {
		@include breakpoint(xsmall up) {
			margin-right: use_gutter(0.5);
			float: right;
		}

		// icon
		@include button-icon($position: left);

		&::before {
			color: $emergency;
		}

		@include breakpoint(xxsmall only) {
			display: block;
		}
	}
}
