/**
 * Display helpers
 */ 

// Clear
@mixin clear {
	clear: both;

	&:after {
		content: ' ';
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}	
}
%clear {
	@include clear;
}
.clear {
	@extend %clear;
}


// Display
.flex {
	display: flex;
}
.row-wrap {
	flex-flow: row wrap;
}
.inline {
	display: inline;
}
.inline-block {
	display: inline-block;
}
.align-evenly {
	justify-content: space-evenly;
}

.fill-height {
	height: 100%;
}


// Image floats 
img.float-left,
p.float-left img, 
figure.float-left,
.img_left, // legacy - v1 
img.float_left, // legacy - v2.0
p.float_left img // legacy - v2.0
{  
	float: left;
	margin-bottom: .75rem;

	@include breakpoint(699px down) {
		float: none !important;
		width: 100%;
		margin-right: 0;
	}
	@include breakpoint(700px) {
	  margin-right: 1.75rem;
	}
}

img.float-right,
p.float-right img, 
figure.float-right,
.img_right, // legacy - v1
img.float_right, // legacy - v2.0
p.float_right img // legacy - v2.0
{
	float: right;
	margin-bottom: .75rem;

	@include breakpoint(699px down) {
		float: none !important;
		width: 100%;
		margin-left: 0;
	}
	@include breakpoint(700px) {
	  margin-left: 1.75rem;
	}
}
@each $breakpoint in $breakpoint-classes {
	.#{$breakpoint}-float-left {
		@include breakpoint(#{$breakpoint}) {
			float: left;
			margin-right: 1.75rem;
		}
	}
	.#{$breakpoint}-float-right {
		@include breakpoint(#{$breakpoint}) {
			float: right;
			margin-left: 1.75rem;
		}
	}
}


// SVG scaling fix for IE
@mixin svg-container($viewportWidth, $viewportHeight, $svgWidth: 100%) {
  width: $svgWidth;
  height: 1px;
  padding-top: ($viewportHeight / $viewportWidth) * $svgWidth;
  position: relative;
  > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}


// Deprecated classes 
.align_center {
	@extend .text-center; 
}
img.align_center {
	display: block; 
	margin: 0 auto;
}
.align_left {
	@extend .text-left;
}
.align_right {
	@extend .text-right;
}
.align_justify {
	@extend .text-justify;
}

.float_left {
	@extend .float-left;
}
.float_right {
  @extend .float-right;
}

