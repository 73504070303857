.tblProgram {
	th {
		@extend h3;
		@include lowercase;
		font-size: 1.35em;
		padding: $table-padding;
		margin-bottom: 0;
	}
}
#tab_program_about {
	img {
		margin-bottom: .5rem;
	}

	div.text_smaller {
		font-size: 80%;
		line-height: 1.4;
	} 
}

.program-index-summary {
	display: table;
}