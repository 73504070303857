// anchor hover decoration (visual cue)
@mixin box-shadow($h: 0, $v: 2px, $blur: 0, $color: $anchor-color-hover, $context: false) {
  @debug $context;
  @if ($context == 'accordion') {
    $position: inset;
    $h: 3px;
    $v: 0;
    $color: map-get($foundation-palette, darkblue);

    box-shadow: $h $v $blur $color $position;
  }
  @else if ($context == 'anchor') {
    box-shadow: $h $v $blur $color;
  } 
  @else {
    box-shadow: none;
  }
  
}
/* @mixin anchor-hover($include: false, $color: $anchor-color-hover, $width: 2px) {
  @if ($include) {
    box-shadow: 0 $width $color;
  }
  @else if {
    box-shadow: none;
  }
} */

/**
 * "Hidden" anchor link within a block-level element,
 * displayed like a link around the element
 * (for analytics purposes)
 */
@mixin hidden-link($z-index: 1) {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $z-index;
	&:hover, &:active, &:focus {
		cursor: pointer;
	}
}
.hidden-link {
	@include hidden-link;
}