/* - - - - - - - - - - - - - - - - - - - - - - - - -
	
	Related files: 
	- path/to/file

  TABLE OF CONTENTS
  
	1.	Section
	2.	Section
			a.	Subsection

	.slider-block-content
		&.slider-block-pagination

	.slider-block-gallery
`
- - - - - - - - - - - - - - - - - - - - - - - - - */

.slider-block {

	// Defaults
	// - - - - - - - - - - - - - - - - - - - - - - - - -

  .swiper-pagination {
    display: none;
  }  
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

	&.swiper-container-fade {
		.swiper-slide {
			background: #fff;			
		}
	} 

	// Content (open HTML)
	.slide-content {
		padding: use_gutter(1); 
		min-height: 200px; 

		&.align-middle,
		&.align-center {
			display: flex;  
			flex-direction: column;
		}
	}


	// Options
	// - - - - - - - - - - - - - - - - - - - - - - - - -
 	
 	// Pagination
 	&-pagination {
 		.slide-content {
      padding-bottom: 40px;
    }

    .swiper-pagination {
      display: block;
      bottom: 10px;
    }   
 	} //-pagination


 	// Controls
 	&-controls {
 		@include breakpoint(medium) {
	 		.slide-content {
	      padding-left: 3rem;
	      padding-right: 3rem;
	    }
		 	
			&:hover { 
				.swiper-button-next:not(.swiper-button-disabled),
				.swiper-button-prev:not(.swiper-button-disabled) {
					opacity: 1;		
				}
			} 
		}

		.swiper-button-next,
		.swiper-button-prev {			    
			background: rgba(0,119,202,.25);     
	    margin: 0; 
	    display: flex;
	    align-items: center; 
	    justify-content: center; 

	    @include breakpoint(medium) {
	    	top: 0;
	    	height: 100%;
	    	width: use_gutter(2.5);
		    opacity: 0;
		    transition: opacity 300ms ease-in;
		  }

	    &::before {     
	      font-size: 2rem;      
	      color: #0077ca;      
	    }
		} 
	  .swiper-button-next {	    
	    transform: rotate(180deg); 

	    @include breakpoint(medium) {
	    	right: 0;
	    }
	  }
	  .swiper-button-prev {
	  	@include breakpoint(medium) {
	    	left: 0;
	    }
	  }
 	} //-controls

}