/**
 * Button to toggle top nav menu (quick links/search)
 * (brand arrow in top right corner)
 */

// Temporary fix for March 27 during header republish period;
// Resolves buttons styles for previous html layout
.toggle_top_nav .align-right {
	.button {
		margin-right: 7px;
		
		&[class*=icon_]::after {
			font-size: 1.15rem;
		}
	}
}


header .sticky.is-stuck {
	.toggle-top-nav,
	.toggle_top_nav {
		display: none;
	}
}

.top-nav-button {
  &-arrow, &-logout {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &-logout {
    display: block;
    right: 200px;
    font: normal 14px/30px $font-heading;
    color: $darkblue;
    white-space: nowrap;
    text-align: right;

    &::before {
      @include icon;
			content: get-icon('icon_lock');
			margin-right: 0.25rem;
    }

    @include breakpoint(small down) {
      line-height: 20px;
      font-size: 12px;
      right: 165px;
    }

    @include breakpoint(xxsmall only) {
      right: 155px;
    }
  }

  &-arrow {
    right: 0;

    .top-nav-button {
      display: block;
      width: 200px;
      height: 60px;
      outline: 0;
      color: $darkblue;
      transition: all $transition-duration ease;
      
      &::after {
        @include brand-chevron($size: 60px, $color: $orange, $thickness: 30px);
        position: absolute;
        top: 0;
        right: 0;
        animation: pulse 10s ease-out 5s infinite;
        transition: all .3s ease-out;
        transform-origin: right top;
      }
      // &::before,
      // &::after {
      // 	display: block;
      // 	content: '';
      // 	position: absolute;
      // }
      // &::before {
      // 	top: 30px;
      // 	right: 30px;
      // 	width: 38px;
      // 	height: 38px;
      // 	background: $white;
      // 	z-index: 1;
      // }
      // &::after {
      // 	top: 0;
      // 	right: 0;
      // 	width: 60px;
      // 	height: 60px;
      // 	background: $orange;
      // 	animation: pulse 10s ease-out 5s infinite;
      // 	transition: all .3s ease-out;
      // 	transform-origin: right top;
      // }

      .desc {
        display: block;
        position: absolute;
        top: 0;
        right: 75px;
        font: bold 14px/30px $font-heading;
        white-space: nowrap;
        text-align: right;
        text-transform: uppercase;
      }

      @include breakpoint(small down) {
        // &::before {
        // 	top: 25px;
        // 	right: 25px;
        // 	width: 32px;
        // 	height: 32px;
        // }
        width: 165px;
        height: 50px;

        &::after {
          width: 50px;
          height: 50px;
          border-width: 25px 25px 0 0;
        }

        .desc {
          line-height: 20px;
          font-size: 12px;
          right: 60px;
        }
      }
      @include breakpoint(xxsmall only) {
        // &::before {
        // 	top: 20px;
        // 	right: 20px;
        // 	width: 25px;
        // 	height: 25px;
        // }
        width: 155px;
        height: 40px;
        
        &::after {
          width: 40px;
          height: 40px;
          border-width: 20px 20px 0 0;
        }

        .desc {
          line-height: 20px;
          font-size: 12px;
          right: 50px;
        }
      }

      [class*=icon_] {
        margin-right: 2px;
      }

      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
        color: $lightblue;
        
        &::after {
          border-color: scale-color($color: $orange, $lightness: -15%);
        }
      }
    }
  }
}

@keyframes pulse {
	from {
		transform: scale(1);
	}
	2% {
    transform: scale(1.1);
	}

	3% {
    transform: scale(1);
	}

  100% {
    transform: scale(1);
  }
}

.top-nav-buttons {
	position: absolute;
	top: 0;
	right: 0;

	@include breakpoint(xxsmall only) {
		width: 100%;
		text-align: center;

		// essential accessibility icon
		#essential-accessibility {
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	@include breakpoint(small down) {
		.button {
			@include button-icon-center();
			border-top-width: 0 !important;

			span {
				display: none;
			}
		}
	}

	@include breakpoint(medium) {
		position: static;
		margin-top: 1.2rem;
		text-align: right;

		.top-nav-button {
			@include button-icon-left();
			// border: none !important;
			padding: 1rem .65rem;
			outline: none;
			font-family: $font-heading;
			font-size: rem-calc(14); //.9375rem !important;
			font-weight: 900;
			text-transform: uppercase;
			color: #003c71;
			transition: color .3s ease;

			&::before {
				padding-right: .5rem;
				font-size: 1rem;
			}
			
			&:hover, &:active, &:focus {
				color: $orange;
				cursor: pointer;
			}
		}
	}

	#essential-accessibility {
		display: inline-block;

		img {
			width: 60px;
			margin-left: .5rem;
		}
	}
}

.top-nav {
	position: relative;
}