/**
 *
 *	Related files: 
 *	- layout/header/header
 *
 *  TABLE OF CONTENTS
 *	
 *	1.	Nav layout 
 *	2.	Main menu (top level)
 *	3. 	Drilldown submenu (general menu settings)
 *	4.	Standard menu
 *	5.	Mega menu
 *	6.	Menu panel
 *	7.	Page overlay
 *
 *	8.	uoit.ca/academics menu
 *
 */

$main-menu-item-color: $white;
$main-menu-item-color-active: $darkblue;
$main-menu-item-color-active-secondary: $accent;
$main-menu-background-color: $darkblue;
$main-menu-item-background-hover: $white;
$scroll-padding-top: rem-calc(75);

// Temporary fix for March 27 during header republish period;
// Resolves header styles for previous html layout
.nav-row {
	background: #fff;
	.logo-container {
		max-width: 80rem;
		margin: 0 auto;
	}
	// .nav-container {
	// 	background: rgba($main-menu-background-color, .85);
	// }
	.menu-main {
		max-width: $global-width - use_gutter();
		margin: 0 auto;
		justify-content: flex-start
	}
}



// Hide menu until Foundation loaded (prevent FOUC)
.no-js {
	@include breakpoint(medium) {

		.menu-main .menu,
		.is-sidebar-menu {
			display: none;
			opacity: 0;
		}

	}
}

// Add padding above an anchored element to account for sticky header 
[id] {	
	scroll-margin-top: $scroll-padding-top;
	box-sizing: border-box;
	// If element is tab/accordion, account for tab links height
	&.tabs-panel,
	&.accordion-content {
		scroll-margin-top: $scroll-padding-top + rem-calc(45);
	}
}
// html {
// 	scroll-padding-top: $scroll-padding-top;
// }

// 1. Nav layout
// -------------

// .nav-bar>div {
// 	@include breakpoint(small down) {
// 		@include flex-grid-column($gutters: 0);
// 	}

// 	@include breakpoint(medium up) {
// 		// margin-top: 2.31rem;
// 		@include flex-grid-row();
// 		@include flex-align(justify, bottom);
// 	}

// 	@include breakpoint(xxsmall only) {
// 		@include flex-align(center, null);
// 	}
// }


// 2. Main menu (top level)
// ------------

.nav-bar {
	background: $main-menu-background-color;
	@include breakpoint(medium) {
		background: rgba($main-menu-background-color, .85);
	}
}

.nav-container {
	@media print {
		display: none;
	}
}

.menu-main {
	// flex-wrap: wrap;
	a {
		font-family: $font-heading;
		outline: none;

		&[data-symlink]:after {
			@include icon;
			content: get-icon('icon_mouse_window');
			vertical-align: super;
			font-size: 0.6875rem;
			margin-left: 0.5rem;
		}

		&[data-login]:after {
			@include icon;
			content: get-icon('icon_lock');
			vertical-align: super;
			font-size: 0.6875rem;
			margin-left: 0.5rem;
		}
	}

	ul {
		background: $main-menu-background-color;
		flex-wrap: wrap;
	}

	@include breakpoint(medium up) {

		// top level links
		>li {
			>a {
				white-space: nowrap;
				outline: none;
				text-transform: uppercase;
				color: $main-menu-item-color;

				&.is-active,
				&:hover,
				&:active,
				&:focus {
					background: rgba($darkorange, .9);// rgba($accent-color,.9);
				}
			}

			&:first-child a {
				margin-left: 0;
			}

			&:last-child a {
				margin-right: 0;
			}
		}

		// active link 	
		li.active>a {
			color: $main-menu-item-color-active;
		}
	}
}

.sticky.is-stuck {
	@media print {
		display: none;
	}
}


// 3. Drilldown submenu (general menu settings)
// --------------------

.is-drilldown-submenu {
	z-index: 1;
}

// Drilldown parent (top level) 
.is-drilldown {
	li {
		display: flex !important;
		flex-flow: row nowrap;

		a {
			width: 100%;
			align-items: center;
		}
	}
}

// Submenu index (parent) page link
li.is-submenu-parent-item>a {
	font-weight: bold;
}

// Submenu with separate clickable 'More' link
.menu a.more {
	display: flex;
	justify-content: center;
	align-items: center !important;
	min-width: 45px; // IE fix
	text-align: center;

	@include breakpoint(small down) {
		color: get-color(grey);
	}

	&:hover,
	&:active,
	&:focus {
		@include breakpoint(small down) {
			color: get-color(grey);
		}
	}

	// arrow icon
	&::before {
		@include icon;
		content: get-icon(icon_arrow);
		font-size: .85rem;
		line-height: normal;
		@include transform(rotate(180deg));
	}
}

// Back button
.menu .js-drilldown-back>a::before {
	@include icon;
	content: get-icon(icon_arrow);
	border: 0;
	width: auto;
	height: auto;
	margin-right: .5rem;
	font-size: .85rem;
	line-height: normal;
}

// Default submenu link (not separate clickable link)
.drilldown[data-clickable-more="false"] {
	.is-drilldown-submenu-parent>a {
		position: relative;

		&::after {
			@include css-triangle($drilldown-arrow-size, $drilldown-arrow-color, $global-right);
			position: absolute;
			top: 50%;
			margin-top: -1 * $drilldown-arrow-size;
			#{$global-right}: 1rem;
		}
	}

	.js-drilldown-back > a::before {
		@include css-triangle($drilldown-arrow-size, $drilldown-arrow-color, $global-left);
		border-#{$global-left}-width: 0;
		display: inline-block;
		vertical-align: middle;
		margin-#{$global-right}: 0.75rem;
		border-#{$global-left}-width: 0;
	}
}



// 4. Standard menu 
// ----------------

.has-standard-menu {
	position: relative;

	.is-drilldown {
		position: absolute;
		top: auto;
		left: 0;
		opacity: 0;
		visibility: hidden;
		box-shadow: 0 1.75rem 0 $main-menu-background-color, 0 -1.75rem 0 $main-menu-background-color;
		background: $main-menu-background-color;
		margin-top: 1.75rem;

		&.nav-visible {
			visibility: visible;
			opacity: 1;
		}

		@include breakpoint(medium up) {
			width: map-get($offcanvas-sizes, small) + 50;
		}
	}

	.is-standard-menu {
		display: none; // hide until drilldown is initialized
	}

	a {
		color: $main-menu-item-color;
	}

	// active link; separate page link and submenu-arrow link
	li>a:first-child {
		&:hover,
		&:active,
		&:focus {
			background: $main-menu-item-background-hover;
			color: $main-menu-item-color-active;

			& + a.more {
				color: $main-menu-item-color-active-secondary;
			}
		}
	}
	li>a.more {
		&:hover,
		&:active,
		&:focus {
			background: $main-menu-item-background-hover;
			color: $main-menu-item-color-active-secondary;
		}
	}
}


// 5. Mega menu
// ------------

.is-mega-menu {
	@include grid-row($size: 'expand'); //grid-row($width: 100%);
	position: absolute;
	top: auto;
	left: 0;
	width: 100%;
	max-height: 530px;
	background: $main-menu-background-color;
	visibility: hidden;
	overflow: hidden;
	opacity: 0;
	@include transition(all $transition-duration linear);

	&.nav-visible {
		visibility: visible;
		opacity: 1;
	}
	>.row {
		max-width: $global-width - use_gutter();
	}
	// mega menu columns
	>.row>.column {
		@include grid-column-collapse;
		margin-top: 1.75rem;
		margin-bottom: 1.75rem;
		border-right: 1px solid rgba(0, 0, 0, .1);

		&:last-child {
			border-right: 0;
		}
	}

	// mega menu column index link 
	.is-mega-column-index {
		@include typography-secondary-heading;
		display: block;
		padding: 0 1rem 0.7rem;

		@include breakpoint(medium up) {
			color: $main-menu-item-color;
			&:hover,
			&:active,
			&:focus {
				background: transparent !important;
				color: $accent-color;
			}
		}
	}

	.is-mega-column {
		max-height: 440px;
		overflow-y: auto;
	}

	.is-drilldown-submenu {
		display: block;
		background: $main-menu-background-color;
	}

	// active link
	@include breakpoint(medium up) {
		// active link
		li.active>a {
			color: $main-menu-item-color-active;
		}

		// separate page link and submenu-arrow link
		li>a:first-child {
			&:hover,
			&:active,
			&:focus {
				background: $main-menu-item-background-hover;
				color: $main-menu-item-color-active;
	
				& + a.more {
					color: $main-menu-item-color-active-secondary;
				}
			}
		}
		li>a.more {
			&:hover,
			&:active,
			&:focus {
				background: $main-menu-item-background-hover;
				color: $main-menu-item-color-active-secondary;
			}
		}
	}

	a {
		outline: none;
		font-family: $font-heading;
		color: $main-menu-item-color;
	}
	
}

.close-mega-menu {
	position: absolute;
	top: 0;
	right: 0;

	.button {
		color: $white;
		@include button-icon-center;

		&.hollow {
			border: none;
		}
	}
}



// 6. Menu panel
// -------------

.is-mega-menu.has-menu-panel {

	// navigation
	>.row>.column:not(.is-menu-panel) {
		width: 25%;
		@extend .shrink;
	}

	// panel section
	>.row .is-menu-panel {
		@include grid-column-gutter(30);
	}

	// images
	figure {
		position: relative;
		margin-bottom: 1rem;

		img {
			width: 100%;
			height: auto;
		}

		figcaption {
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 90%;
			margin: 0;
			padding: 1rem;
			z-index: 2;
			font-weight: bold;
			color: #fff;
			// @include typography-primary-link(#fff);

			// chevron
			&::after {
				display: inline-block;
				content: '\203A';
				font-size: 1.5rem;
				line-height: 0;
				color: $accent-color;
				transition: .5s ease;
				transform: translate(-150%, 2px);
				opacity: 0;
			}
		}
		// hover effect - chevron
		&:hover figcaption::after {
			transform: translate(5px, 2px);
			opacity: 1;
		}

		// black overlay
		&:not(.disable-gradient) {
			@include gradient-overlay($position: bottom);
		}
	}

	// callout
	.callout {
		img {
			width: 100%;
			height: auto;
			margin-bottom: 1rem;
		}
	}

	// content
	[class*='float']~div,
	[class*='float']~p,
	[class*='float']~ul {
		display: table;
	}

	.is-menu-panel {
		>.row>.column {
			color: $grey; // #fff;
		}

		li>a:first-child {

			&:hover,
			&:active,
			&:focus {
				background: transparent;
				color: $anchor-color;
			}
		}

		a.button {
			color: $button-color;
		}
	}

	// headings
	.menu-panel-header {
		@include typography-secondary-heading($white);
		line-height: 1.5;
	}

	.menu-panel-subheader {
		font-size: 1.2rem;
		font-family: $font-heading;
		font-weight: 900;
		text-transform: uppercase;
		color: $accent-color;
	}
}


// 7. Page overlay
// ---------------

.menu-page-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: $offcanvas-exit-background;
	z-index: 3;
	display: none;

	&.nav-visible {
		// display: block;
	}
}



// 8. uoit.ca/academics menu
// -------------------------

.is-mega-menu#mega_academics {
	background: #fff;

	>.row>.column {
		border-right: 0;
	}

	span.fac {
		@include breakpoint(medium) {
			display: block;
			@include serif(italic);
			font-size: 1.2rem;
			color: $grey;
		}
	}

	span:not(.fac) {
		font-weight: 900;
		font-size: rem-calc(26);
		text-transform: uppercase;
		line-height: 1;
	}
}