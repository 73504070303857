// Sticky, rotated button attached to right side
.sticky-side-button {
	position: fixed;
	right: 0;
	top: 50%;
	transform: rotate(-270deg) translateX(50%);
	transform-origin: right top;
	z-index: 11;

	.button {
		margin: 0;
	}

	button[data-close] {
		padding-left: 5px;
		padding-right: 5px;
		transform: rotate(90deg);
	}

	@each $color,
	$value in $official-palette {
		&.#{$color},
		&.#{$color} button[data-close] {
			background-color: scale-color($value, $lightness: -15%);
			color: color-pick-contrast($value, ($button-color, $button-color-alt));
		}
	}
}
