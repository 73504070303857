/**
 *
 *  TABLE OF CONTENTS
 *  
 *  - FOOTER TOP SECTION
 *  - FOOTER BOTTOM SECTION
 *    1. Logo
 *    2. Address/copyright
 *    3. Tagline and social media icons
 *    4. Sticky footer buttons 
 *       a. Back to top
 *       b. Feedback
 *
 */

footer.footer {
	background: $footer-background;
	padding: rem-calc(30) 0 rem-calc(20); 
	
	@media print {
		display: none;
	}
	
	@include breakpoint(medium) {
    align-self: flex-end;
    width: 100%; 
  }
}


// Footer links section
.footer_links {
	border-bottom: 1px solid $border-color;
	margin-bottom: rem-calc(30);
	padding-bottom: rem-calc(15);
}

// Footer and Header quick links
.top_links,
.footer_links {
	@include breakpoint(xsmall down) {
		@include flex-grid-layout(2);
		.column {
			margin: .5rem 0;
		}
	}

  @include breakpoint(small) {
		justify-content: space-between;

  	.column {
      @include flex-grid-column(shrink);
    }
	}

	font-family: $font-heading;

  p.header { 
		margin-bottom: 20px;
		font-size: 1.25rem;
		font-weight: bold;
		text-transform: uppercase;
		color: $footer-link-color;
		
		&::after {
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			margin-top: 1rem;
			background: $orange;
		}
  }
    
  ul {
    margin-left: 0;
  }

  li {
    margin: 10px 0;
    list-style: none;

    &:first-child {
      margin-top: 0; 
    }
    
    a {
			color: $footer-link-color;
			border-bottom: 1px dotted transparent;
			
			&:hover, &:active, &:focus {
				border-bottom: 1px dotted $footer-link-color; 
			}
    } 
  }      
} 



/**
 * FOOTER LOGO/ADDRESS
 */

.footer_sec_bottom {
	background: $footer-bottom-background;	
	padding: rem-calc(30) 0;

	@include breakpoint(large) {
	  padding: rem-calc(40) 0;
	}
} 

// 1. Logo
// -------

.logo_footer {
	@include flex-grid-column(shrink);

	@include breakpoint(xsmall down) {
		@include flex-grid-column(12);
		text-align: center;
	}

	img {
		width: 200px;
	}
}

// 2. Address/copyright
// ----------------------

.footer_address {
	@include flex-grid-column;
	
	@include breakpoint(small) {
		margin-left: 8.3333%;
	}

	@include breakpoint(large) {
		@include flex-grid-column(7);
	}

	font-size: .88rem;
	line-height: normal;
	color: $white;

}

// 3. Social media icons
// ---------------------

.footer_social_icons {    
	width: 200px;
	margin: 2rem auto;

  ul {
		display: flex;
		justify-content: space-between;
		margin: 0;
    font-size: 1.5rem;
		list-style: none;
	}
	
	a {
		display: block;
		color: $white;
	}
}

// 4. Sticky footer
// ----------------

.footer_sticky { 
  position: fixed;
  bottom: -1px; 
  right: 0; 
  width: 100%;
  z-index: 5; 
}

// 4.a.
// ---- 

.btn-to-top {
  margin-right: use_gutter(); 
  background: $orange;// scale-color($orange, $lightness: $button-function-factor);  
  transition: 0.375s ease-out;
  transform: translateY(100%);
	@include typography-primary-link($white);
  // @include typography-primary-link($color: $white, $hover: $white, $size: rem-calc(19)); 
    
  // text 
  span {
    display: inline-block;
    padding: .15rem .45rem;
    background: $darkorange; 
    @include transition(background .375s ease);
  }
  &:hover, &:active, &:focus {
    span { 
      background: scale-color($darkorange, $lightness: $button-function-factor);
    }
  }

  // icon
  &::before {
    @include icon;
    @include transform(rotate(90deg));
    content: get-icon(icon_arrow);
    margin-right: .25rem;
    margin-left: .4rem;  
  } 
  
  // show button
  &.active {
    transform: translateY(0);
  }
} 

// 4.b. Site feedback form button
// ------------------------------

.btn-site-feedback {
  display: inline-block; 
  margin-right: use_gutter(); 
  background: $darkblue; 
  @include typography-primary-link(#fff);  
  @include transition(background .375s ease);

  span {
    display: inline-block;
    padding: .15rem .45rem;
  }

  &:hover, &:active, &:focus {
    background: scale-color($darkblue, $lightness: $button-function-factor);
  }
}
